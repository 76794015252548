"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonStyle = exports.centerStyle = void 0;
const react_1 = require("@emotion/react");
exports.centerStyle = (0, react_1.css) `
  text-align: center;
`;
exports.buttonStyle = (0, react_1.css) `
  background-color: blue;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  padding: 0.5em;
  transition-property: background-color;
  transition-duration: 0.6s;
  &:hover {
    background-color: purple;
  }
`;
