"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tooltipVisibleStyle = exports.tooltipStyle = exports.tooltipWrapperStyle = void 0;
const react_1 = require("@emotion/react");
const tooltipWrapperStyle = (0, react_1.css) `
  position: relative;
  display: inline-block;
`;
exports.tooltipWrapperStyle = tooltipWrapperStyle;
const tooltipStyle = (x, y) => (0, react_1.css) `
  z-index: 400;
  position: absolute;
  left: 50%;
  transform: translateX(${x}%) translateY(${y}%); /* Center the tooltip */
  background-color: #333; /* Nicer background color */
  color: #fff; /* White text color */
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
`;
exports.tooltipStyle = tooltipStyle;
const tooltipVisibleStyle = (0, react_1.css) `
  opacity: 1;
  visibility: visible;
`;
exports.tooltipVisibleStyle = tooltipVisibleStyle;
