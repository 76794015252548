"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iframeWrapperStyle = void 0;
const react_1 = require("@emotion/react");
const iframeWrapperStyle = (0, react_1.css) `
  position: 'relative';
  overflow: 'hidden';
  // paddingBottom: '56.25%';
  gridarea: 'video';
`;
exports.iframeWrapperStyle = iframeWrapperStyle;
