"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentStyle = exports.specDetailsStyle = exports.aboutUsPageTextStyle = exports.localContainerStyle = exports.specsContainerStyle = exports.pageTitleStyle = exports.pageContainerStyle = exports.noTextDecoration = void 0;
/** @jsxImportSource @emotion/react */
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
exports.noTextDecoration = (0, react_1.css) `
  & a,
  & a:hover,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: inherit;
  }
`;
exports.pageContainerStyle = (0, react_1.css) `
  ${exports.noTextDecoration};
  padding: 2em;
  background-color: #f7f9fc;
  color: #333;
`;
exports.pageTitleStyle = (0, react_1.css) `
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 1em;
  text-align: center;
  color: #333;
`;
exports.specsContainerStyle = (0, react_1.css) `
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;
exports.localContainerStyle = (0, react_1.css) `
  padding: 1.5em;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f0f4f8;
`;
exports.aboutUsPageTextStyle = (0, react_1.css) `
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr; /* Ensure same grid layout */
  padding: 1em 2em; /* Added padding for spacing */
  background-color: #3f51b5; /* Accent Color */
  color: #fff;
  font-weight: bold;
  text-align: center;

  ${global_1.mediumScreen} {
    grid-template-columns: 1fr 2fr 2fr;
    text-align: left;
  }

  ${global_1.smallScreen} {
    grid-template-columns: 1fr;
    text-align: left;
    padding: 1em;
  }
`;
exports.specDetailsStyle = (0, react_1.css) `
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr; /* Ensure same grid layout */
  grid-gap: 1em;
  padding: 1.5em 2em; /* Added padding for spacing */
  background-color: #fff;
  color: #333;

  ${global_1.mediumScreen} {
    grid-template-columns: 1fr 2fr 2fr;
    padding: 1.5em 1em;
  }

  ${global_1.smallScreen} {
    grid-template-columns: 1fr;
    padding: 1.5em 1em;
  }

  p {
    margin: 0.5em 0;
    font-size: 0.9em;
  }
`;
exports.contentStyle = (0, react_1.css) `
  padding: 1em 2em; /* Added padding for spacing */
  text-align: center;
  background-color: #e8f0fe; /* Light Blue for contrast */
  font-size: 0.8em;
`;
