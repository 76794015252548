"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_helmet_1 = require("react-helmet");
const react_router_dom_1 = require("react-router-dom");
const MetaTags = ({ title, content, ogTitle, type, ogUrl, ogDescription, ogImageUrl, }) => {
    const path = (0, react_router_dom_1.useLocation)().pathname;
    return ((0, jsx_runtime_1.jsxs)(react_helmet_1.Helmet, { children: [(0, jsx_runtime_1.jsxs)("title", { children: [title, " "] }), (0, jsx_runtime_1.jsx)("meta", { name: "description", content: content }), (0, jsx_runtime_1.jsx)("link", { rel: "canonical", href: `https://www.comparegameserverhosting.com${path}` }), (0, jsx_runtime_1.jsx)("meta", { property: "og:description", content: ogDescription || 'Compare game server hosts' }), (0, jsx_runtime_1.jsx)("meta", { property: "og:site_name", content: "HostFilter" }), (0, jsx_runtime_1.jsx)("meta", { property: "og:title", content: ogTitle || 'title' }), (0, jsx_runtime_1.jsx)("meta", { property: "og:type", content: type || 'website' }), (0, jsx_runtime_1.jsx)("meta", { property: "og:url", content: ogUrl || 'url' }), (0, jsx_runtime_1.jsx)("meta", { property: "og:image", content: ogImageUrl || 'missing-picture' })] }));
};
exports.default = MetaTags;
