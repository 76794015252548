"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.establishedStyles = exports.tpClassStyle = exports.couponBar = exports.couponContainer = void 0;
/** @jsxImportSource @emotion/react */
const react_1 = require("@emotion/react");
exports.couponContainer = (0, react_1.css) `
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    width: 95%;
    margin: 0 auto;
  }
`;
exports.couponBar = (0, react_1.css) `
  border-radius: 0 0 5px 5px;
  @media (max-width: 768px) {
    margin: 0 1em;
  }
`;
exports.tpClassStyle = (0, react_1.css) `
  width: 1.5em;
  height: 1.5em;
  font-size: 1em;
  background-color: red;
  border-radius: 50%;
  color: white;
  cursor: help;
`;
exports.establishedStyles = (0, react_1.css) `
  position: absolute;
  top: 40%;
  left: 31%;
  transform: rotate(5deg);
  font-weight: bolder;
  font-size: 0.5vw;
  @media (max-width: 768px) {
    font-size: 2vw;
  }
  @media (max-width: 480px) {
    font-size: 8vw;
  }
`;
