"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noTextDecoration = exports.largerThanSmall = exports.mobileOnlyStyle = exports.noMobileStyle = exports.tooltipStyle = exports.largerThanSmallScreen = exports.tinyScreen = exports.notTinyScreen = exports.smallScreen = exports.mediumScreen = exports.largeScreen = void 0;
const react_1 = require("@emotion/react");
exports.largeScreen = '@media only screen and (min-width: 1751px)';
exports.mediumScreen = '@media only screen and (max-width: 1750px)';
exports.smallScreen = '@media only screen and (max-width: 1200px)';
exports.notTinyScreen = '@media only screen and (max-width: 800px)';
exports.tinyScreen = '@media only screen and (max-width: 600px)';
exports.largerThanSmallScreen = '@media only screen and (min-width: 1200px)';
exports.tooltipStyle = (0, react_1.css) `
  line-height: 1em;
  font-size: 1em;
  opacity: 1;
`;
exports.noMobileStyle = (0, react_1.css) `
  ${exports.smallScreen} {
    display: none;
  }
`;
exports.mobileOnlyStyle = (0, react_1.css) `
  ${exports.largeScreen} {
    display: none;
  }
`;
exports.largerThanSmall = (0, react_1.css) `
  ${exports.largerThanSmallScreen} {
    display: none;
  }
`;
exports.noTextDecoration = (0, react_1.css) `
  & a,
  & a:hover,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: white;
  }
`;
