"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inactiveBannerImagesHoverStyle = exports.bannerImagesHoverStyle = exports.notInList = exports.flagBannerImageStyling = exports.displayGrid = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const displayGrid = (0, react_1.css) `
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;
exports.displayGrid = displayGrid;
const flagBannerImageStyling = (0, react_1.css) `
  width: 50px;
  height: 50px;
  padding: 1em;
  margin-left: 1%;
  margin-right: 1%;

  ${global_1.mediumScreen} {
    width: 50px;
    height: 50px;
    padding: 0.3em;
  }

  ${global_1.smallScreen} {
    width: 50px;
    height: 50px;
    padding: none;
  }
`;
exports.flagBannerImageStyling = flagBannerImageStyling;
const notInList = (0, react_1.css) `
  opacity: 0.2;
  cursor: default;
`;
exports.notInList = notInList;
const bannerImagesHoverStyle = (0, react_1.css) `
  transition: all 0.2s ease-in-out;
  transform: scale(1.25);

  &:hover {
    transform: scale(1.4);
    z-index: 2;
    opacity: 1;
  }
`;
exports.bannerImagesHoverStyle = bannerImagesHoverStyle;
const inactiveBannerImagesHoverStyle = (0, react_1.css) `
  transition: all 0.2s ease-in-out;
  transform: scale(1.25);

  &:hover {
    transform: scale(1.4);
    z-index: 1;
    opacity: 1;
  }
`;
exports.inactiveBannerImagesHoverStyle = inactiveBannerImagesHoverStyle;
