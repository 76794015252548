"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roadmapGrid = exports.dataGridItems = exports.dataGrid = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const dataGrid = (0, react_1.css) `
  ul,
  p,
  h2 {
    padding: 0;
    margin: 0;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 3fr;
  border: 1px solid white;
  grid-gap: 0.2em;
  align-items: center;

  @media (max-width: ${global_1.smallScreen}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;
exports.dataGrid = dataGrid;
const dataGridItems = (0, react_1.css) `
  padding-left: 0.5em;
  text-align: left;
`;
exports.dataGridItems = dataGridItems;
const roadmapGrid = (0, react_1.css) `
  grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr 0.5fr 3fr;

  @media (max-width: ${global_1.smallScreen}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;
exports.roadmapGrid = roadmapGrid;
