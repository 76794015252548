"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageCallToAction = exports.gameDescStyle = exports.gameTitleStyle = exports.gameSynopsisStyle = exports.largerFlex = exports.instructionsStyle2 = exports.instructionsStyleBanners = exports.instructionsStyle = exports.iframeVideo = exports.dualInstruction = exports.guideLinkStyle = exports.instructionContainerStyle = exports.showAllGamesButtonStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global"); // adjust the import path as necessary
exports.showAllGamesButtonStyle = (0, react_1.css) `
  padding: 0.5em 0;
  width: 100%;
  color: white;
  background-color: darkblue;
`;
exports.instructionContainerStyle = (0, react_1.css) `
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 6em;

  ${global_1.smallScreen} {
    width: 90%;
  }
`;
exports.guideLinkStyle = (0, react_1.css) `
  width: fit-content;
  margin: 0.5em auto 0;
  background-color: #1f1f1f;
  border-radius: 3px;
  padding: 0.5em;
  color: white;

  a,
  a:visited,
  a:link {
    text-decoration: none;
  }
`;
exports.dualInstruction = (0, react_1.css) `
  display: flex;

  ${global_1.notTinyScreen} {
    flex-direction: column;
  }

  ${global_1.smallScreen} {
    width: 100%;
  }
`;
exports.iframeVideo = (0, react_1.css) `
  display: flex;
  justify-content: center;
  padding: 0.5em;
  align-items: center;
  background-color: #0a062e;
  margin: 0.3em 0 0.3em 0.3em;
  border: 3px solid grey;

  ${global_1.smallScreen} {
    margin: 0 0 0 0.1em;
  }

  ${global_1.notTinyScreen} {
    margin: 0.1em 0 0 0;
  }
`;
exports.instructionsStyle = (0, react_1.css) `
  display: flex;
  flex-direction: row;
  background-color: #0a062e;
  color: white;
  font-size: 0.6em;
  border: 3px solid grey;
  margin-bottom: 0.2em;
  padding-right: 0.5em;
  grid-template-columns: 1fr 9fr;
  margin: 0.5em auto;
  box-sizing: border-box;

  ${global_1.mediumScreen} {
    flex-direction: column;
    align-items: center;
  }

  ${global_1.smallScreen} {
    margin: 0 auto;
    width: -webkit-fill-available;
  }
`;
exports.instructionsStyleBanners = (0, react_1.css) `
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #0a062e;
  color: white;
  font-size: 0.6em;
  border: 3px solid grey;
  margin-bottom: 0.2em;
  padding-bottom: 0.5em;
  padding-right: 0.5em;
  margin: 0.5em auto;
  box-sizing: border-box;
`;
exports.instructionsStyle2 = (0, react_1.css) `
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #0a062e;
  color: white;
  font-size: 0.6em;
  border: 3px solid grey;
  margin-bottom: 0.2em;
  padding-right: 0.5em;
  margin: 0.5em auto;
  box-sizing: border-box;

  ${global_1.smallScreen} {
    display: none;
  }
`;
exports.largerFlex = (0, react_1.css) `
  flex: 2 1 auto;
  padding: 1em;

  ${global_1.mediumScreen} {
    flex-direction: column;
  }
`;
exports.gameSynopsisStyle = (0, react_1.css) `
  h1 {
    color: white;
  }
  p {
    color: #c8c8c8;
  }
  .game-desc {
    grid-area: desc;
    columns: 2;
  }
  .iframe-wrapper {
    height: 100%;
    text-align: center;
  }
  .game-title {
    grid-area: title;
    text-align: center;
  }
  .guide {
    grid-area: guide;
    align-self: center;
    justify-self: center;
    background-color: red;
    padding: 1em;
    color: white;
    border-radius: 40%;
    transition:
      background-color 1s ease,
      color 1s ease;
    &:hover {
      background-color: pink;
    }
  }
  & a {
    text-decoration: none;
    color: white;
  }
  & a:visited {
    color: white;
  }
  & a:link {
    color: white;
  }

  ${global_1.largeScreen} {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas: 'title title guide' 'desc desc video';
    grid-gap: 1em;
    .game-desc {
      columns: 2;
      text-align: left;
    }
  }
  @media only screen and (min-width: 1500px) {
    width: 80%;
    box-sizing: border-box;
  }
  padding: 0.5em 1em 1em 1em;
  margin-bottom: 1em;
  border-radius: 1em;
`;
exports.gameTitleStyle = (0, react_1.css) `
  grid-area: title;
  font-size: 1.5em;
`;
exports.gameDescStyle = (0, react_1.css) `
  grid-area: desc;
  column-count: 3;
`;
exports.imageCallToAction = (0, react_1.css) `
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.1em;
  width: 80%;
  font-size: 2em;
  color: white;
  background-color: #ec48f0;
  &:hover {
    background-color: red;
  }
`;
