"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serverPageContainerStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global"); // Add import statement for smallScreen
exports.serverPageContainerStyle = (0, react_1.css) `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  color: white;
  background-color: #151443;
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto 1em auto;
  padding: 1em;

  ${global_1.smallScreen} {
    width: 90%;
  }
`;
