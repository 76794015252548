"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const styles_1 = require("./styles");
const transitions_1 = require("../styles/transitions");
const Featured = ({ children, shouldShow }) => {
    const fadeClass = shouldShow ? transitions_1.fadeIn : transitions_1.fadeOut;
    const classes = [styles_1.featuredHost, fadeClass];
    return ((0, jsx_runtime_1.jsxs)("fieldset", { css: classes, className: "featured-host", children: [(0, jsx_runtime_1.jsx)("legend", { children: shouldShow ? 'Featured' : '' }), children] }));
};
exports.default = Featured;
