"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.backDropStyling = void 0;
const react_1 = require("@emotion/react");
const backDropStyling = (0, react_1.css) `
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
exports.backDropStyling = backDropStyling;
