"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.featuredHost = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
exports.featuredHost = (0, react_1.css) `
  margin: 0 auto;
  display: flex;
  color: white;
  background-color: purple;
  ${global_1.largeScreen} {
    width: 80%;
  }
`;
// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `
// const fadeOut = keyframes`
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 2;
//   }
// `
// export const fadeInAnimation = css`
//   animation: ${fadeIn} 0.5s ease-in-out;
// `
// export const fadeInAnimation = css`
//   animation: ${fadeIn} 0.5s ease-in-out;
// `
