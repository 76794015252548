"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const react_1 = require("@emotion/react");
const react_2 = require("react");
const react_router_dom_1 = require("react-router-dom");
const styles_1 = require("../styles");
const guidePanel = (0, react_1.css) `
  position: fixed;
  display: flex;
  z-index: 3;
  flex-direction: column;
  background-color: darkgrey;
`;
const GuideMenu = () => {
    return ((0, jsx_runtime_1.jsx)(react_2.Fragment, { children: (0, jsx_runtime_1.jsxs)("ul", { css: guidePanel, className: "guide-panel", children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.navBarLinkStyle, to: "/guides/rust", children: "Rust Guide" }), (0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.navBarLinkStyle, to: "/guides/7-days-to-die", children: "7 Days Guide" }), (0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.navBarLinkStyle, to: "/guides/ark-survival-evolved", children: "Ark Guide" })] }) }));
};
exports.default = GuideMenu;
