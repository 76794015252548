"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateData = exports.roadmapData = void 0;
exports.roadmapData = [
    {
        name: 'Game Filter',
        UX: 'Yes',
        UI: 'Yes',
        bug: 'No',
        feature: 'Yes',
        description: [
            'Clicking on game filter only shows available countries and hosts for that game',
            'clicking on hosts only shows available countries and games for that host',
            'clicking on a country only shows available games and hosts for that country',
        ],
    },
    {
        name: 'Mobile menus',
        UX: 'Yes',
        UI: 'Yes',
        bug: 'No',
        feature: 'Yes',
        description: ['Animate menu show and hide on mobile'],
    },
    {
        name: 'Improve admin pages layout',
        UX: 'Yes',
        UI: 'Yes',
        bug: 'No',
        feature: 'No',
        description: [
            'Improve the layout of the admin pages, roadmap, contact us etc.',
        ],
    },
];
exports.updateData = [
    {
        version: '5.1.1',
        month: 'August',
        day: '17',
        year: '2024',
        UX: 'yes',
        UI: 'yes',
        bug: 'yes',
        feature: 'yes',
        server: 'no',
        description: [
            'add featured host for all hosts and for each game',
            'fix sorting on numbers crashing',
        ],
    },
    {
        version: '5.0.18',
        month: 'August',
        day: '13',
        year: '2024',
        UX: 'yes',
        UI: 'yes',
        bug: 'yes',
        feature: 'no',
        server: 'no',
        description: [
            'update specs page',
            'add soulmask',
            'fix double render',
            'add missing guide images',
        ],
    },
    {
        version: '4.0.3',
        month: 'April',
        day: '29',
        year: '2024',
        UX: 'no',
        UI: 'no',
        bug: 'yes',
        feature: 'No',
        server: 'no',
        description: [
            'fix missing data when going from the home page to the game pages and needing to refresh',
            'fix amazon laptop link"',
        ],
    },
    {
        version: '4.0.2',
        month: 'April',
        day: '24',
        year: '2024',
        UX: 'no',
        UI: 'no',
        bug: 'yes',
        feature: 'No',
        server: 'no',
        description: ['fix missing data'],
    },
    {
        version: '4.0.1',
        month: 'April',
        day: '21',
        year: '2024',
        UX: 'yes',
        UI: 'yes',
        bug: 'no',
        feature: 'No',
        server: 'yes',
        description: [
            'update to latest react',
            'small refactor for UI improvements, text size',
        ],
    },
    {
        version: '3.9.13',
        month: 'April',
        day: '09',
        year: '2024',
        UX: 'yes',
        UI: 'yes',
        bug: 'no',
        feature: 'No',
        server: 'yes',
        description: [
            'improve db efficiency',
            'remove host less than 4 stars',
            'refactor and UI improvements',
        ],
    },
    {
        version: '3.9.12',
        month: 'April',
        day: '09',
        year: '2024',
        UX: 'yes',
        UI: 'yes',
        bug: 'no',
        feature: 'No',
        server: 'no',
        description: ['improve contrast ratio of some items'],
    },
    {
        version: '3.9.11',
        month: 'April',
        day: '09',
        year: '2024',
        UX: 'yes',
        UI: 'yes',
        bug: 'yes',
        feature: 'No',
        server: 'yes',
        description: [
            'adde stablished logo to autogeneration',
            'add correct default image path for multiple sources',
            'image log aspect ratios honoured',
        ],
    },
    {
        version: '3.9.10',
        month: 'April',
        day: '08',
        year: '2024',
        UX: 'no',
        UI: 'yes',
        bug: 'No',
        feature: 'No',
        description: ['add srcset and pictureset to amazon links'],
    },
    {
        version: '3.9.9',
        month: 'April',
        day: '08',
        year: '2024',
        UX: 'no',
        UI: 'yes',
        bug: 'No',
        feature: 'No',
        description: ['add aria-label to toggle button'],
    },
    {
        version: '3.9.8',
        month: 'April',
        day: '08',
        year: '2024',
        UX: 'no',
        UI: 'yes',
        bug: 'No',
        feature: 'No',
        description: ['add correct amazon image location'],
    },
    {
        version: '3.9.7',
        month: 'April',
        day: '08',
        year: '2024',
        UX: 'no',
        UI: 'yes',
        bug: 'No',
        feature: 'No',
        description: ['refactor and UI improvements', 'add missing game images'],
    },
    {
        version: '3.9.4',
        month: 'April',
        day: '08',
        year: '2024',
        UX: 'no',
        UI: 'yes',
        bug: 'No',
        feature: 'No',
        description: ['refactor and UI improvements'],
    },
    {
        version: '3.9.3',
        month: 'April',
        day: '08',
        year: '2024',
        UX: 'no',
        UI: 'no',
        bug: 'No',
        feature: 'No',
        description: [
            'improve image generation and location',
            'images fit the size they are supposed to be',
            'update images',
        ],
    },
    {
        version: '3.9.2',
        month: 'April',
        day: '05',
        year: '2024',
        UX: 'Yes',
        UI: 'Yes',
        bug: 'No',
        feature: 'No',
        description: [
            'make buy buttons clickable size on mobile',
            'give the amazon links a pretty picture',
        ],
    },
    {
        version: '3.9.1`',
        month: 'April',
        day: '04',
        year: '2024',
        UX: 'Yes',
        UI: 'Yes',
        bug: 'Yes',
        feature: 'Yes',
        description: [
            'Add better home screen layout for host images',
            'specify size of images for logos, banners',
            'trustpilot',
            'support and countries',
            'remove instructions',
            'update max-age for assets',
            'lazy load below the fold ImageList',
            'update serverlist styling',
            'remove unnecessary commented code',
            'remove unnecessary trustpilot stars',
            'limit reviews to 4',
            'add more games',
            'update trust pilot url to correct url',
            'remove dropdown filters',
            'remove duped ids for filters',
            'remove greyed out games to reduce necessity for image downloads SEO',
            'update sitemap generation',
            'make the site more mobile friendly',
            'add lots more game descriptions',
        ],
    },
    {
        version: '3.3.1`',
        month: 'February',
        day: '05',
        year: '2023',
        UX: 'Yes',
        UI: 'Yes',
        bug: 'No',
        feature: 'No',
        description: [
            'Add better home screen layout for host images',
            'update menu css',
        ],
    },
];
