"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCurrentCountry = exports.setCurrentPlatform = exports.setCurrentHost = exports.setCurrentGame = exports.currentGameHostInitialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.currentGameHostInitialState = {
    currentGame: 'all',
    currentHost: 'all',
    currentPlatform: 'all',
    currentCountry: 'all',
};
const currentGameHostSlice = (0, toolkit_1.createSlice)({
    name: 'currentGameHost',
    initialState: exports.currentGameHostInitialState,
    reducers: {
        setCurrentGame: (state, action) => {
            state.currentGame = action.payload;
        },
        setCurrentHost: (state, action) => {
            state.currentHost = action.payload;
        },
        setCurrentPlatform: (state, action) => {
            state.currentPlatform = action.payload;
        },
        setCurrentCountry: (state, action) => {
            state.currentCountry = action.payload;
        },
    },
});
_a = currentGameHostSlice.actions, exports.setCurrentGame = _a.setCurrentGame, exports.setCurrentHost = _a.setCurrentHost, exports.setCurrentPlatform = _a.setCurrentPlatform, exports.setCurrentCountry = _a.setCurrentCountry;
exports.default = currentGameHostSlice.reducer;
