"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navBarStyle = exports.siteLogoStyle = exports.versionNumberStyle = exports.navBarLinkStyle = exports.navBarLinksStyle = exports.linkStyle = exports.backgroundColorDefault = exports.spacer = exports.toolbarLogo = exports.linky = exports.toolbarNavigationItems = exports.toolbarNavigation = exports.toolbar = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const backgroundColorDefault = '#1f1f1f';
exports.backgroundColorDefault = backgroundColorDefault;
const linkStyle = (0, react_1.css) `
  text-decoration: none;
  color: white;
`;
exports.linkStyle = linkStyle;
const navBarStyle = (0, react_1.css) `
  ${linkStyle};
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 5fr;
  margin: 0 auto;
  width: 80%;
  ${global_1.smallScreen} {
    font-size: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0.5em 0;
  }
`;
exports.navBarStyle = navBarStyle;
const siteLogoStyle = (0, react_1.css) `
  ${linkStyle};
  justify-self: flex-start;
  ${global_1.smallScreen} {
    text-align: center;
  }
`;
exports.siteLogoStyle = siteLogoStyle;
const navBarLinksStyle = (0, react_1.css) `
  position: relative;
  z-index: 200;
  a {
    position: relative;
  }
  ${global_1.largeScreen} {
    font-size: 0.5em;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
  ${global_1.smallScreen} {
    margin-top: 0.5em;
    font-size: 1em;
    display: none; /* changed by the hamburger menu icon */
    flex-direction: column;
    text-align: left;
  }
`;
exports.navBarLinksStyle = navBarLinksStyle;
const navBarLinkStyle = (0, react_1.css) `
  ${linkStyle};
  ${global_1.largeScreen} {
    &:hover {
      background-color: #3e3e3e;
    }
    padding: 1em;
  }
  ${global_1.smallScreen} {
    margin: 0.1em;
    border: 1px solid white;
    padding: 0.5em;
    width: 100%;
    background-color: #3e3e3e;
  }
`;
exports.navBarLinkStyle = navBarLinkStyle;
const versionNumberStyle = (0, react_1.css) `
  color: white;
  margin-right: 1em;
  text-align: right;
  background-color: #1f1f1f;
  width: 100%;
  justify-self: flex-end;
  font-weight: normal;
  font-size: 0.7vw;
  ${global_1.smallScreen} {
    font-size: 1em;
  }
`;
exports.versionNumberStyle = versionNumberStyle;
const toolbar = (0, react_1.css) `
  top: 0;
  left: 0;
  z-index: 4;
  position: fixed;
  width: 100%;
  background-color: ${backgroundColorDefault};
  height: 75px;
`;
exports.toolbar = toolbar;
const toolbarNavigation = (0, react_1.css) `
  display: flex;
  height: 100%;
  align-items: center;
`;
exports.toolbarNavigation = toolbarNavigation;
const toolbarNavigationItems = (0, react_1.css) `
  display: flex;
  align-items: center;
  height: 100%;

  a {
    &:hover {
      background-color: #3e3e3e;
    }
    height: 100%;
    color: white;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-content: center;

    li {
      height: 100%;

      ${global_1.mediumScreen} {
        padding: 0 0.5rem;
      }

      a {
        height: 100%;
      }
    }
  }
`;
exports.toolbarNavigationItems = toolbarNavigationItems;
const linky = (0, react_1.css) `
  height: 100%;
`;
exports.linky = linky;
const toolbarLogo = (0, react_1.css) `
  margin-left: 1rem;

  a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
  }
`;
exports.toolbarLogo = toolbarLogo;
const spacer = (0, react_1.css) `
  flex: 1;
`;
exports.spacer = spacer;
