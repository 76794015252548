"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serverPanel = void 0;
const react_1 = require("@emotion/react");
const serverPanel = (0, react_1.css) `
  position: fixed;
  display: flex;
  z-index: 3;
  flex-direction: column;
  background-color: darkgrey;
`;
exports.serverPanel = serverPanel;
