"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allPlatformClass = exports.otherPlatformClass = exports.currentPlatformClass = exports.bannerImagesHoverStyle = exports.platformImageAllStyle = exports.platformImageOtherStyle = exports.platformImageCurrentStyle = exports.platformImageBaseStyle = exports.displayGrid = void 0;
const react_1 = require("@emotion/react");
const displayGrid = (itemCount) => (0, react_1.css) `
  display: grid;
  grid-template-columns: repeat(${itemCount}, 1fr);
  gap: 10px;
  align-items: center;
`;
exports.displayGrid = displayGrid;
exports.platformImageBaseStyle = (0, react_1.css) `
  margin-left: 1%;
  margin-right: 1%;
  width: 50px;
  height: 50px;
  object-fit: contain;
`;
exports.platformImageCurrentStyle = (0, react_1.css) `
  transform: scale(1.25);
  opacity: 1;
`;
exports.platformImageOtherStyle = (0, react_1.css) `
  transform: scale(1);
  opacity: 0.2;
`;
exports.platformImageAllStyle = (0, react_1.css) `
  opacity: 1;
`;
exports.bannerImagesHoverStyle = (0, react_1.css) `
  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  }
`;
exports.currentPlatformClass = `${exports.platformImageBaseStyle} ${exports.platformImageCurrentStyle} ${exports.bannerImagesHoverStyle}`;
exports.otherPlatformClass = `${exports.platformImageBaseStyle} ${exports.platformImageOtherStyle} ${exports.bannerImagesHoverStyle}`;
exports.allPlatformClass = `${exports.platformImageBaseStyle} ${exports.platformImageAllStyle} ${exports.bannerImagesHoverStyle}`;
