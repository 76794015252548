"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonStyling = void 0;
const react_1 = require("@emotion/react");
exports.buttonStyling = (0, react_1.css) `
  width: 80%;
  padding: 1em 0;
  background-color: #3f51b5;
  border-radius: 0.5em;
  outline: none;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: #3f51ff;
    transform: translateY(1em);
  }
`;
