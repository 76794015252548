"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const react_1 = require("react");
const styles_1 = require("./styles");
const TooltipHolder = ({ title, children }) => {
    const [visible, setVisible] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)("div", { css: styles_1.tooltipWrapperStyle, onMouseEnter: () => setVisible(true), onMouseLeave: () => setVisible(false), children: [children, (0, jsx_runtime_1.jsx)("div", { css: [(0, styles_1.tooltipStyle)(-50, 0), visible && styles_1.tooltipVisibleStyle], children: title })] }));
};
exports.default = TooltipHolder;
