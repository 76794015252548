"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const react_i18next_1 = require("react-i18next");
const styles_1 = require("./styles");
const Coupons = ({ coupon_expiry, coupon_description, coupons, }) => {
    const { t } = (0, react_i18next_1.useTranslation)();
    const expiryDate = new Date(coupon_expiry.split('T')[0]);
    const isValid = expiryDate > new Date();
    return isValid ? ((0, jsx_runtime_1.jsx)("div", { className: "coupon-box", css: styles_1.couponBar, children: (0, jsx_runtime_1.jsxs)("div", { className: "coupon-container", css: styles_1.couponContainer, children: [(0, jsx_runtime_1.jsx)("span", { children: coupon_description }), (0, jsx_runtime_1.jsx)("span", { children: coupons })] }) })) : null;
};
exports.default = Coupons;
