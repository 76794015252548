"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleServerBlockDediTitleStyle = exports.serverBlockDediStyle = exports.singleServerBlockTitleStyle = exports.serverBlockStyle = exports.mobileSortContainer = exports.mobileSort = exports.pointer = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../global"); // adjust the import path as necessary
exports.pointer = (0, react_1.css) `
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
exports.mobileSort = (0, react_1.css) `
  color: whitesmoke;
  background-color: black;
  padding: 1em;
  border: 1px solid white;
  flex-grow: 1;
  text-align: center;
`;
exports.mobileSortContainer = (0, react_1.css) `
  display: flex;
  color: white;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  ${global_1.smallScreen} {
    display: none;
  }
`;
exports.serverBlockStyle = (0, react_1.css) `
  width: 100%;
  border: 1px solid grey;
  color: rgb(237, 224, 223);
  background-color: #1f1f1f;
  margin: 0 auto;
  margin-bottom: 0.25em;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
  ${global_1.smallScreen} {
    display: none;
  }
`;
exports.singleServerBlockTitleStyle = (0, react_1.css) `
  display: grid;
  font-weight: bolder;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  ${global_1.tinyScreen} {
    margin: 1em;
    display: grid;
    align-items: start;
    grid-template-columns: 1% 1fr 1fr 1fr 1%;
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      '. mainImageLogo trustpilotArea platforms .'
      '. established locations support .'
      '. slots slots controlPanel .'
      '. link link link .';
    justify-content: center;
  }
  .main-image-logo {
    .image-logos {
      display: block;
      cursor: pointer;
    }
  }
  ${global_1.smallScreen} {
    display: none;
    .locations,
    .established,
    .control-panel,
    .platforms,
    .support {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    .established {
      flex-direction: row;
    }
  }
  ${global_1.mediumScreen} {
    display: grid;
    text-align: center;
    grid-template-rows: 1fr;
    align-items: center;
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr 0.85fr 1fr 2fr;
    font-size: 0.65vw;
    grid-gap: 0.2em;
    grid-template-areas: 'mainImageLogo trustpilotArea slots locations platforms controlPanel support established link';
  }
  ${global_1.largeScreen} {
    display: grid;
    text-align: center;
    grid-template-rows: 1fr;
    align-items: center;
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr 0.85fr 1fr 2fr;
    font-size: 0.65vw;
    grid-gap: 0.2em;
    grid-template-areas: 'mainImageLogo trustpilotArea slots locations platforms controlPanel support established link';
  }
`;
exports.serverBlockDediStyle = (0, react_1.css) `
  border: 1px solid grey;
  color: rgb(237, 224, 223);
  background-color: #1f1f1f;
  margin: 0 auto;
  margin-bottom: 1em;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
  width: 80%;
  ${global_1.smallScreen} {
    border: none;
  }
`;
exports.singleServerBlockDediTitleStyle = (0, react_1.css) `
  font-weight: bolder;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  ${global_1.notTinyScreen} {
    margin: 1em;
    display: grid;
    align-items: start;
    grid-template-columns: 1% 1fr 1fr 1fr 1%;
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      '. mainImageLogo trustpilotArea platforms .'
      '. established locations support .'
      '. slots slots controlPanel .'
      '. link link link .';
    justify-content: center;
  }
  .main-image-logo {
    .image-logos {
      cursor: pointer;
    }
  }
  ${global_1.smallScreen} {
    .locations,
    .established,
    .control-panel,
    .platforms,
    .support {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    .established {
      flex-direction: row;
    }
  }
  ${global_1.largeScreen} {
    display: grid;
    text-align: center;
    grid-template-rows: 1fr;
    align-items: center;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    font-size: 0.65vw;
    grid-gap: 0.2em;
    grid-template-areas: 'mainImageLogo trustpilotArea price memory hdd ipGroup locations os support established link';
  }
`;
