"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monthYear = void 0;
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const monthYear = (date) => {
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
};
exports.monthYear = monthYear;
