"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameGuideStyle = exports.guideStyle = exports.guideTable = void 0;
const react_1 = require("@emotion/react");
const guideTable = (0, react_1.css) `
  color: white;
  background-color: blue;
  text-align: center;
`;
exports.guideTable = guideTable;
const guideStyle = (0, react_1.css) `
  text-align: center;
  ul {
    list-style: none;
    padding: 0;
  }
`;
exports.guideStyle = guideStyle;
const gameGuideStyle = (0, react_1.css) `
  background-color: #1f1f1f;
  box-sizing: border-box;
  padding: 1em;
  width: 80%;

  & .image-container {
    text-align: center;
  }

  & .image-container img {
    max-width: 100%;
  }

  margin: 0 auto;

  & a:visited,
  & a:link {
    color: white;
  }

  & a:visited {
    color: white;
  }

  & a:hover {
    color: pink;
  }

  code {
    background-color: grey;
    padding: 0.2em;
    border-radius: 2px;
  }

  & h2 {
    margin-top: 1em;
    text-align: center;
    color: white;
  }

  .content {
    color: white;

    @media (min-width: 768px) {
      column-count: 3;
    }

    & p {
    }
  }
`;
exports.gameGuideStyle = gameGuideStyle;
