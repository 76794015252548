"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLoading = exports.setUniqueGames = exports.setUniqueHosts = exports.setServerData = exports.setSortedServerData = exports.fetchData = exports.gameServerDataInitialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.gameServerDataInitialState = {
    serverData: [],
    sortedServerData: [],
    uniqueHosts: [],
    uniqueGames: [],
    loading: false,
};
// Async thunk for fetching data
exports.fetchData = (0, toolkit_1.createAsyncThunk)('gameServerData/fetchData', async () => {
    const response = await fetch('/api/data');
    const data = await response.json();
    return data;
});
// Slice
const gameServerDataSlice = (0, toolkit_1.createSlice)({
    name: 'gameServerData',
    initialState: exports.gameServerDataInitialState,
    reducers: {
        setSortedServerData: (state, action) => {
            state.serverData = action.payload || [];
        },
        setServerData: (state, action) => {
            state.serverData = action.payload || [];
        },
        setUniqueHosts: (state, action) => {
            state.uniqueHosts = action.payload || [];
        },
        setUniqueGames: (state, action) => {
            state.uniqueGames = action.payload || [];
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchData.pending, (state) => {
            state.loading = true;
        })
            .addCase(exports.fetchData.fulfilled, (state, action) => {
            state.loading = false;
            state.serverData = action.payload.serverData || [];
            state.sortedServerData = action.payload.serverData || [];
            state.uniqueHosts = action.payload.uniqueHosts || [];
            state.uniqueGames = action.payload.uniqueGames || [];
        })
            .addCase(exports.fetchData.rejected, (state) => {
            state.loading = false;
        });
    },
});
_a = gameServerDataSlice.actions, exports.setSortedServerData = _a.setSortedServerData, exports.setServerData = _a.setServerData, exports.setUniqueHosts = _a.setUniqueHosts, exports.setUniqueGames = _a.setUniqueGames, exports.setLoading = _a.setLoading;
exports.default = gameServerDataSlice.reducer;
