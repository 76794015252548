"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rssLink = exports.rssContent = exports.readMoreLink = exports.rssItem = exports.imageDirection = exports.rssItemsContainer = exports.rssContainer = void 0;
const react_1 = require("@emotion/react");
exports.rssContainer = (0, react_1.css) `
  display: flex;
  flex-direction: column;
`;
exports.rssItemsContainer = (0, react_1.css) `
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const imageDirection = (direction) => (0, react_1.css) `
  float: ${direction % 2 === 0 ? 'right' : 'left'};
  height: auto;
  width: 40%;
  margin: 1em;
`;
exports.imageDirection = imageDirection;
const rssItem = (direction) => (0, react_1.css) `
  justify-content: ${direction % 2 === 0 ? 'flex-start' : 'flex-end'};
  max-width: 400px;
  width: 80%;
  &:hover {
    background-color: darkgrey;
  }
  cursor: pointer;
  flex-grow: 0;
  text-overflow: ellipsis;
  a,
  a:visited,
  a:link {
    text-decoration: none;
    color: white;
  }
  margin: 5px;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 10px;
  background-color: grey;
  text-decoration: none;
`;
exports.rssItem = rssItem;
exports.readMoreLink = (0, react_1.css) `
  &:link,
  &:visited {
    color: red;
  }
  &:hover {
    color: orange;
  }
  color: white;
  padding: 0.5em;
  align-self: flex-end;
  border-radius: 5px;
  background-color: #1f1f1f;
  margin-top: 1rem;
`;
exports.rssContent = (0, react_1.css) `
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;
exports.rssLink = (0, react_1.css) `
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
