"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleButtonLine = exports.toggleButton = void 0;
const react_1 = require("@emotion/react");
const toggleButton = (0, react_1.css) `
  height: 24px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;
exports.toggleButton = toggleButton;
const toggleButtonLine = (0, react_1.css) `
  width: 30px;
  height: 2px;
  background: white;
`;
exports.toggleButtonLine = toggleButtonLine;
