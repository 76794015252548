"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setGameSpecData = exports.fetchSpecData = exports.gameSpecDataInitialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.gameSpecDataInitialState = {
    gameSpecData: [],
    loading: false,
};
exports.fetchSpecData = (0, toolkit_1.createAsyncThunk)('gameSpecData/fetchSpecData', async () => {
    const response = await fetch('/api/specs');
    const data = await response.json();
    return data;
});
const GameSpecDataSlice = (0, toolkit_1.createSlice)({
    name: 'gameSpecData',
    initialState: exports.gameSpecDataInitialState,
    reducers: {
        setGameSpecData: (state, action) => {
            state.gameSpecData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchSpecData.pending, (state) => {
            state.loading = true;
        })
            .addCase(exports.fetchSpecData.fulfilled, (state, action) => {
            state.loading = false;
            state.gameSpecData = action.payload.gameSpecData || [];
        })
            .addCase(exports.fetchSpecData.rejected, (state) => {
            state.loading = false;
        });
    },
});
exports.setGameSpecData = GameSpecDataSlice.actions.setGameSpecData;
exports.default = GameSpecDataSlice.reducer;
