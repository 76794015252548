"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fadeIn = exports.fadeOut = void 0;
const react_1 = require("@emotion/react");
exports.fadeOut = (0, react_1.css) `
  opacity: 0;
  transform: scaleY(0);
  max-height: 0px;
  overflow: hidden;
  transform-origin: top;
  transition: all 1s ease;
`;
exports.fadeIn = (0, react_1.css) `
  opacity: 1;
  max-height: 2000px;
  transform: scaleY(1);
  transform-origin: top;
  transition: all 1s ease;
`;
