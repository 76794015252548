"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageStyle = exports.starsContainerStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
exports.starsContainerStyle = (0, react_1.css) `
  width: auto;
  margin: 0 auto;
`;
exports.imageStyle = (0, react_1.css) `
  width: 150px;
  height: 75px;
  object-fit: contain;

  ${global_1.smallScreen} {
    width: 90px;
    height: 45px;
  }
`;
