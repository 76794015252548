"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageLogosStyle = exports.imageStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const imageStyle = (0, react_1.css) `
  width: 50px;
  height: 50px;
  ${global_1.smallScreen} {
    max-width: 30vw;
  }
`;
exports.imageStyle = imageStyle;
const imageLogosStyle = (0, react_1.css) `
  color: white;
  font-weight: bolder;
`;
exports.imageLogosStyle = imageLogosStyle;
