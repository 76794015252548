"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const thisPath = '/images/bannerImages/normal/webp';
const VideoLinks = {
    all: {
        video: 'https://www.youtube.com/embed/IoOrY-MPVzw',
        image: `${thisPath}/all.webp`,
        categories: [],
        gamePurchaseLink: '',
    },
    '7-days-to-die': {
        video: 'https://www.youtube.com/embed/nluen7EUb1Q',
        image: `${thisPath}/7-days-to-die.webp`,
        categories: ['Survival', 'FPS'],
        gamePurchaseLink: 'https://store.steampowered.com/app/251570/7_Days_to_Die/',
    },
    'alien-arena': {
        video: 'https://www.youtube.com/watch?v=fxMJ1bWdtzs',
        image: `${thisPath}/alien-arena.webp`,
        categories: ['Arena Shooter', 'Action', 'Indie', 'Gore', 'Violent'],
        gamePurchaseLink: 'https://store.steampowered.com/app/629540/Alien_Arena_Warriors_Of_Mars/',
    },
    'ark-survival-evolved-genesis': {
        video: 'https://www.youtube-nocookie.com/embed/Mgmk9MGoQtY',
        image: `${thisPath}/ark-survival-evolved-genesis.webp`,
        categories: [
            'Action',
            'Adventure',
            'Indie',
            'RPG',
            'Massively Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/346110/ARK_Survival_Evolved/',
    },
    'arma-reforger': {
        video: 'https://www.youtube.com/embed/AIervYhiEZ8',
        image: `${thisPath}/arma-reforger.webp`,
        categories: ['Action', 'Simulation', 'Strategy', 'Shooter', 'Military'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1874880/Arma_Reforger/',
    },
    astroneer: {
        video: 'https://www.youtube.com/embed/UMLwzt5t9bs',
        image: `${thisPath}/astroneer.webp`,
        categories: ['Adventure', 'Indie', 'Sandbox', 'Space', 'Open World'],
        gamePurchaseLink: 'https://store.steampowered.com/app/361420/ASTRONEER/',
    },
    artemishea: {
        video: 'https://www.youtube.com/embed/GYjeYHmjUsk',
        image: `${thisPath}/artemishea.webp`,
        categories: ['Action', 'Adventure', 'RPG', 'Indie', 'Fantasy'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1555220/Artemishea/',
    },
    argo: {
        video: 'https://www.youtube.com/embed/FxI6uWMa5q0',
        image: `${thisPath}/argo.webp`,
        categories: ['Free to Play', 'Shooter', 'FPS', 'Multiplayer', 'Action'],
        gamePurchaseLink: 'https://store.steampowered.com/app/530700/Argo/',
    },
    'ark-survival-evolved': {
        video: 'https://www.youtube.com/embed/FW9vsrPWujI',
        image: `${thisPath}/ark-survival-evolved.webp`,
        categories: [
            'Action',
            'Adventure',
            'Indie',
            'RPG',
            'Massively Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/346110/ARK_Survival_Evolved/',
    },
    'arma-3': {
        video: 'https://www.youtube.com/embed/Jioo5uXoNLg',
        image: `${thisPath}/arma-3.webp`,
        categories: ['Action', 'Simulation', 'Strategy', 'Shooter', 'Military'],
        gamePurchaseLink: 'https://store.steampowered.com/app/107410/Arma_3/',
    },
    atlas: {
        video: 'https://www.youtube.com/embed/5klldYiDAm0',
        image: `${thisPath}/atlas.webp`,
        categories: [
            'Adventure',
            'Massively Multiplayer',
            'RPG',
            'Action',
            'Survival',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/834910/ATLAS/',
    },
    'core-keeper': {
        video: 'https://www.youtube.com/embed/eqrtsgoU8J8',
        image: `${thisPath}/core-keeper.webp`,
        categories: ['Indie', 'Adventure', 'Action', 'Sandbox', 'RPG'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1621690/Core_Keeper/',
    },
    'conan-exiles': {
        video: 'https://www.youtube.com/embed/mzubhJpbUK8',
        image: `${thisPath}/conan-exiles.webp`,
        categories: [
            'Action',
            'Adventure',
            'Massively Multiplayer',
            'RPG',
            'Survival',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/440900/Conan_Exiles/',
    },
    CSGO: {
        video: 'https://www.youtube.com/embed/edYCtaNueQY',
        image: `${thisPath}/CSGO.webp`,
        categories: ['FPS', 'Shooter', 'Multiplayer', 'Competitive', 'Action'],
        gamePurchaseLink: 'https://store.steampowered.com/app/730/CounterStrike_Global_Offensive/',
    },
    pixark: {
        video: 'https://www.youtube.com/embed/Tnt6epYpLAs',
        image: `${thisPath}/pixark.webp`,
        categories: [
            'Survival',
            'Adventure',
            'Open World',
            'Massively Multiplayer',
            'RPG',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/593600/PixARK/',
    },
    unturned: {
        video: 'https://www.youtube.com/embed/CXs71qEvLNU',
        image: `${thisPath}/unturned.webp`,
        categories: [
            'Free to Play',
            'Survival',
            'Zombies',
            'Multiplayer',
            'Open World',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/304930/Unturned/',
    },
    minecraft: {
        video: 'https://www.youtube.com/embed/MmB9b5njVbA',
        image: `${thisPath}/minecraft.webp`,
        categories: ['Sandbox', 'Survival', 'Building', 'Adventure', 'Multiplayer'],
        gamePurchaseLink: 'https://www.minecraft.net/zh-hans',
    },
    staxel: {
        video: 'https://www.youtube.com/embed/oOYhBFmXPyU',
        image: `${thisPath}/staxel.webp`,
        categories: [
            'Farming Sim',
            'Character Customization',
            'Cute',
            'Agriculture',
            'Sandbox',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/405710/Staxel/',
    },
    'space-engineers': {
        video: 'https://www.youtube.com/embed/WrePWKCSB5c',
        image: `${thisPath}/space-engineers.webp`,
        categories: ['Sandbox', 'Space', 'Building', 'Simulation', 'Sci-Fi'],
        gamePurchaseLink: 'https://store.steampowered.com/app/244850/Space_Engineers/',
    },
    'outlaws-of-the-old-west': {
        video: 'https://www.youtube.com/embed/W6xn9UGvh0E',
        image: `${thisPath}/outlaws-of-the-old-west.webp`,
        categories: ['Survival', 'Open World', 'Western', 'Building', 'Action'],
        gamePurchaseLink: 'https://store.steampowered.com/app/840800/Outlaws_of_the_Old_West/',
    },
    miscreated: {
        video: 'https://www.youtube.com/embed/2tUo5xsNuGQ',
        image: `${thisPath}/miscreated.webp`,
        categories: [
            'Survival',
            'Open World',
            'Post-apocalyptic',
            'Multiplayer',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/299740/Miscreated/',
    },
    hurtworld: {
        video: 'https://www.youtube.com/embed/RdvbYG_dSyI',
        image: `${thisPath}/hurtworld.webp`,
        categories: ['Survival', 'Open World', 'Multiplayer', 'Action', 'Shooter'],
        gamePurchaseLink: 'https://store.steampowered.com/app/393420/Hurtworld/',
    },
    'empyrion-galactic-survival': {
        video: 'https://www.youtube.com/embed/aFkmj3DuDgw',
        image: `${thisPath}/empyrion-galactic-survival.webp`,
        categories: [
            'Simulation',
            'Co-op',
            'Open World Survival Craft',
            'Space',
            'Survival',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/383120/Empyrion__Galactic_Survival/',
    },
    'eco-global-survival': {
        video: 'https://www.youtube.com/embed/KkJtpCddzII',
        image: `${thisPath}/eco-global-survival.webp`,
        categories: ['Simulation', 'Building', 'Open World', 'Survival', 'Sandbox'],
        gamePurchaseLink: 'https://store.steampowered.com/app/382310/Eco/',
    },
    dayz: {
        video: 'https://www.youtube.com/embed/7jk8prJA9nI',
        image: `${thisPath}/dayz.webp`,
        categories: ['Survival', 'Zombies', 'Open World', 'Multiplayer', 'Shooter'],
        gamePurchaseLink: 'https://store.steampowered.com/app/221100/DayZ/',
    },
    'dont-starve-together': {
        video: 'https://www.youtube.com/embed/bVbyn7c1X6E',
        image: `${thisPath}/dont-starve-together.webp`,
        categories: ['Survival', 'Adventure', 'Indie', 'Multiplayer', 'Sandbox'],
        gamePurchaseLink: 'https://store.steampowered.com/app/322330/Dont_Starve_Together/',
    },
    squad: {
        video: 'https://www.youtube.com/embed/iDDiDALh9Do',
        image: `${thisPath}/squad.webp`,
        categories: ['Action', 'Military', 'Simulation', 'Strategy', 'Shooter'],
        gamePurchaseLink: 'https://store.steampowered.com/app/393380/Squad/',
    },
    avorion: {
        video: 'https://www.youtube.com/embed/PfhIAmnC9kY',
        image: `${thisPath}/avorion.webp`,
        categories: ['Action', 'Indie', 'Simulation', 'Strategy'],
        gamePurchaseLink: 'https://store.steampowered.com/app/445220/Avorion/',
    },
    wreckfest: {
        video: 'https://www.youtube.com/embed/cbsDiIuI7KQ',
        image: `${thisPath}/wreckfest.webp`,
        categories: [
            'Racing',
            'Destruction',
            'Automobile Sim',
            'Simulation',
            'Driving',
            'Multiplayer',
            'Physics',
            'Action',
            'Indie',
            'Sports',
            'Singleplayer',
            'Realistic',
            'Funny',
            'Early Access',
            'Arcade',
            'Sandbox',
            'Third Person',
            'Open World',
            'Co-op',
            'Atmospheric',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/228380/Wreckfest/',
    },
    'wurm-unlimited': {
        video: 'https://www.youtube.com/embed/kDZJq7jfKIk',
        image: `${thisPath}/wurm-unlimited.webp`,
        categories: [
            'Adventure',
            'Indie',
            'Massively Multiplayer',
            'RPG',
            'Simulation',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/366220/Wurm_Unlimited/',
    },
    insurgency: {
        video: 'https://www.youtube.com/embed/mVvXBtj03xo',
        image: `${thisPath}/insurgency.webp`,
        categories: [
            'Action',
            'Indie',
            'Military',
            'Multiplayer',
            'Shooter',
            'Strategy',
            'Tactical',
            'Team-Based',
            'War',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/222880/Insurgency/',
    },
    'insurgency-sandstorm': {
        video: 'https://www.youtube.com/embed/nXQQDlTI89Q',
        image: `${thisPath}/insurgency-sandstorm.webp`,
        categories: [
            'Action',
            'Atmospheric',
            'Co-op',
            'First-Person',
            'Military',
            'Multiplayer',
            'Realistic',
            'Shooter',
            'Tactical',
            'Team-Based',
            'War',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/581320/Insurgency_Sandstorm/',
    },
    'assetto-corsa': {
        video: 'https://www.youtube.com/embed/-6X29LcfWsQ',
        image: `${thisPath}/assetto-corsa.webp`,
        categories: ['Racing', 'Simulation', 'Sports', 'Automobile Sim'],
        gamePurchaseLink: 'https://store.steampowered.com/app/244210/Assetto_Corsa/',
    },
    'arma-3-exile-mod': {
        video: 'https://www.youtube.com/embed/xbZfyqHZg5Y',
        image: `${thisPath}/arma-3-exile-mod.webp`,
        categories: ['Military', 'Simulation', 'Multiplayer', 'Mod'],
        gamePurchaseLink: 'https://steamcommunity.com/sharedfiles/filedetails/?id=1487484880',
    },
    'arma-3-altis-life': {
        video: 'https://www.youtube.com/embed/PdyDOBy9zAc',
        image: `${thisPath}/arma-3-altis-life.webp`,
        categories: ['Roleplay', 'Simulation', 'Multiplayer', 'Mod'],
        gamePurchaseLink: 'https://store.steampowered.com/curator/9684990-Altis-Life-RPG/',
    },
    'arma-3-breaking-point': {
        video: 'https://www.youtube.com/embed/6nL6rp6w7j8',
        image: `${thisPath}/arma-3-breaking-point.webp`,
        categories: ['Survival', 'Zombie', 'Multiplayer', 'Mod'],
        gamePurchaseLink: 'https://playbreakingpoint.com/',
    },
    'battalion-1944': {
        video: 'https://www.youtube.com/embed/7wQ9dkqxF9Y',
        image: `${thisPath}/battalion-1944.webp`,
        categories: ['Action', 'Shooter', 'Multiplayer', 'World War II'],
        gamePurchaseLink: 'https://store.steampowered.com/app/489940/BATTALION_1944/',
    },
    'beasts-of-bermuda': {
        video: 'https://www.youtube.com/embed/o5i7rnGftQs',
        image: `${thisPath}/beasts-of-bermuda.webp`,
        categories: [
            'Survival',
            'Dinosaurs',
            'Multiplayer',
            'Simulation',
            'Open World',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/719890/Beasts_of_Bermuda/',
    },
    'beyond-the-wire': {
        video: 'https://www.youtube.com/embed/v7XFLFRTGkg',
        image: `${thisPath}/beyond-the-wire.webp`,
        categories: ['Action', 'FPS', 'World War I', 'Multiplayer', 'Simulation'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1058650/Beyond_The_Wire/',
    },
    blackwake: {
        video: 'https://www.youtube.com/embed/LUCMgpYCNLs',
        image: `${thisPath}/blackwake.webp`,
        categories: ['Naval', 'Action', 'Multiplayer', 'FPS', 'Pirates'],
        gamePurchaseLink: 'https://store.steampowered.com/app/420290/Blackwake/',
    },
    'broke-protocol': {
        video: 'https://www.youtube.com/embed/_EDfSaim1JE',
        image: `${thisPath}/broke-protocol.webp`,
        categories: [
            'Action',
            'RPG',
            'Simulation',
            'Massively Multiplayer',
            'Open World',
        ],
        gamePurchaseLink: 'https://brokeprotocol.com/',
    },
    'call-of-duty-2': {
        video: 'https://www.youtube.com/embed/K4eXjltawZ4',
        image: `${thisPath}/call-of-duty-2.webp`,
        categories: ['Action', 'FPS', 'World War II', 'Shooter', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/2630/Call_of_Duty_2/',
    },
    'call-of-duty-4': {
        video: 'https://www.youtube.com/embed/LhuIjNSg7Gg',
        image: `${thisPath}/call-of-duty-4.webp`,
        categories: ['Action', 'Shooter', 'First-Person', 'Multiplayer', 'War'],
        gamePurchaseLink: 'https://store.steampowered.com/app/7940/Call_of_Duty_4_Modern_Warfare/',
    },
    'call-of-duty-world-at-war': {
        video: 'https://www.youtube.com/embed/vMUZDdtjVi8',
        image: `${thisPath}/call-of-duty-world-at-war.webp`,
        categories: [
            'Action',
            'Shooter',
            'First-Person',
            'Multiplayer',
            'World War II',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/10090/Call_of_Duty_World_at_War/',
    },
    'counter-strike-1.6': {
        video: 'https://www.youtube.com/embed/oIoueckSEtU',
        image: `${thisPath}/counter-strike-1.6.webp`,
        categories: [
            'Action',
            'Shooter',
            'First-Person',
            'Multiplayer',
            'Tactical',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/10/CounterStrike/',
    },
    'counter-strike-source': {
        video: 'https://www.youtube.com/embed/2xQLOK3kJK8',
        image: `${thisPath}/counter-strike-source.webp`,
        categories: [
            'Action',
            'Shooter',
            'First-Person',
            'Multiplayer',
            'Tactical',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/240/CounterStrike_Source/',
    },
    'dark-and-light': {
        video: 'https://www.youtube.com/embed/Xjtso9U3SbQ',
        image: `${thisPath}/dark-and-light.webp`,
        categories: ['Survival', 'Open World', 'Sandbox', 'MMO', 'Fantasy'],
        gamePurchaseLink: 'https://store.steampowered.com/app/529180/Dark_and_Light/',
    },
    'day-of-dragons': {
        video: 'https://www.youtube.com/embed/QOAYzGHLU7Y',
        image: `${thisPath}/day-of-dragons.webp`,
        categories: ['Survival', 'Dragons', 'Multiplayer', 'Open World', 'Sandbox'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1088090/Day_of_Dragons/',
    },
    'dead-matter': {
        video: 'https://www.youtube.com/embed/DrlMX-bna4o',
        image: `${thisPath}/dead-matter.webp`,
        categories: ['Survival', 'Zombie', 'Open World', 'Sandbox', 'Multiplayer'],
        gamePurchaseLink: 'https://playdeadmatter.com/',
    },
    factorio: {
        video: 'https://www.youtube.com/embed/BqaAjgpsoW8',
        image: `${thisPath}/factorio.webp`,
        categories: ['Strategy', 'Simulation', 'Building', 'Sandbox', 'Management'],
        gamePurchaseLink: 'https://store.steampowered.com/app/427520/Factorio/',
    },
    'fear-the-night': {
        video: 'https://www.youtube.com/embed/Ag1sa41v23E',
        image: `${thisPath}/fear-the-night.webp`,
        categories: ['Survival', 'Zombie', 'Open World', 'Multiplayer', 'Crafting'],
        gamePurchaseLink: 'https://store.steampowered.com/app/764920/Fear_the_Night/',
    },
    fiveM: {
        video: 'https://www.youtube.com/embed/wwEBYXj4Cbk',
        image: `${thisPath}/fiveM.webp`,
        categories: [
            'Mod',
            'Multiplayer',
            'Open World',
            'Custom Servers',
            'Roleplay',
        ],
        gamePurchaseLink: 'https://fivem.net/',
    },
    'garrys-mod': {
        video: 'https://www.youtube.com/embed/kgXVLw6qpFM',
        image: `${thisPath}/garrys-mod.webp`,
        categories: ['Sandbox', 'Multiplayer', 'Moddable', 'Creative', 'Physics'],
        gamePurchaseLink: 'https://store.steampowered.com/app/4000/Garrys_Mod/',
    },
    'goldeneye-007': {
        video: 'https://www.youtube.com/embed/-E4XtdEnWx4',
        image: `${thisPath}/goldeneye-007.webp`,
        categories: ['Action', 'Shooter', 'Classic', 'Multiplayer', 'Stealth'],
        gamePurchaseLink: '',
    },
    heat: {
        video: 'https://www.youtube.com/embed/t3vJ2eL9q_s',
        image: `${thisPath}/heat.webp`,
        categories: [
            'Survival',
            'Open World',
            'Sandbox',
            'Multiplayer',
            'Building',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/656240/Heat/',
    },
    'hell-let-loose': {
        video: 'https://www.youtube.com/embed/EQhD1_4NQvk',
        image: `${thisPath}/hell-let-loose.webp`,
        categories: ['FPS', 'World War II', 'Tactical', 'Multiplayer', 'Realistic'],
        gamePurchaseLink: 'https://store.steampowered.com/app/686810/Hell_Let_Loose/',
    },
    hellion: {
        video: 'https://www.youtube.com/embed/FsSzoQJek70',
        image: `${thisPath}/hellion.webp`,
        categories: [
            'Space',
            'Survival',
            'Simulation',
            'Multiplayer',
            'Open World',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/588210/HELLION/',
    },
    'interstellar-rift': {
        video: 'https://www.youtube.com/embed/QSOQHFovI4I',
        image: `${thisPath}/interstellar-rift.webp`,
        categories: ['Space', 'Simulation', 'Survival', 'Multiplayer', 'Building'],
        gamePurchaseLink: 'https://store.steampowered.com/app/363360/Interstellar_Rift/',
    },
    'left-4-dead-2': {
        video: 'https://www.youtube.com/embed/9XIle_kLHKU',
        image: `${thisPath}/left-4-dead-2.webp`,
        categories: ['Action', 'Shooter', 'Zombie', 'Multiplayer', 'Co-op'],
        gamePurchaseLink: 'https://store.steampowered.com/app/550/Left_4_Dead_2/',
    },
    'life-is-feudal': {
        video: 'https://www.youtube.com/embed/APL4r7p3vUg',
        image: `${thisPath}/life-is-feudal.webp`,
        categories: ['MMO', 'Survival', 'Sandbox', 'Open World', 'Crafting'],
        gamePurchaseLink: 'https://store.steampowered.com/app/700030/Life_is_Feudal_MMO/',
    },
    'medieval-engineers': {
        video: 'https://www.youtube.com/embed/QWwnJBHsIeo',
        image: `${thisPath}/medieval-engineers.webp`,
        categories: [
            'Building',
            'Sandbox',
            'Medieval',
            'Simulation',
            'Engineering',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/333950/Medieval_Engineers/',
    },
    'memories-of-mars': {
        video: 'https://www.youtube.com/embed/FeAFBgf6ruk',
        image: `${thisPath}/memories-of-mars.webp`,
        categories: ['Survival', 'Open World', 'Sci-Fi', 'Multiplayer', 'Sandbox'],
        gamePurchaseLink: 'https://store.steampowered.com/app/644290/MEMORIES_OF_MARS/',
    },
    mordhau: {
        video: 'https://www.youtube.com/embed/nFpoDlH6Sxs',
        image: `${thisPath}/mordhau.webp`,
        categories: [
            'Action',
            'Medieval',
            'Multiplayer',
            'Melee Combat',
            'First-Person',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/629760/MORDHAU/',
    },
    'post-scriptum': {
        video: 'https://www.youtube.com/embed/TE21qzFuAWY',
        image: `${thisPath}/post-scriptum.webp`,
        categories: [
            'Simulation',
            'World War II',
            'Tactical',
            'Multiplayer',
            'Shooter',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/736220/Post_Scriptum/',
    },
    rust: {
        video: 'https://www.youtube.com/embed/LGcECozNXEw',
        image: `${thisPath}/rust.webp`,
        categories: [
            'Survival',
            'Crafting',
            'Multiplayer',
            'Open World',
            'Building',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/252490/Rust/',
    },
    'project-cars-2': {
        video: 'https://www.youtube.com/embed/z1OjVNZdRwU',
        image: `${thisPath}/project-cars-2.webp`,
        categories: ['Racing', 'Simulation', 'Driving', 'Realistic', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/378860/Project_CARS_2/',
    },
    'project-zomboid': {
        video: 'https://www.youtube.com/embed/4eBy0woHWjA',
        image: `${thisPath}/project-zomboid.webp`,
        categories: ['Survival', 'Zombie', 'Open World', 'Sandbox', 'Simulation'],
        gamePurchaseLink: 'https://store.steampowered.com/app/108600/Project_Zomboid/',
    },
    rend: {
        video: 'https://www.youtube.com/embed/N4vtZ1PxmHE',
        image: `${thisPath}/rend.webp`,
        categories: ['Survival', 'MMO', 'Fantasy', 'Open World', 'Co-op'],
        gamePurchaseLink: 'https://store.steampowered.com/app/547860/Rend/',
    },
    fragmented: {
        video: 'https://www.youtube.com/embed/ZHiUHk30VSw',
        image: `${thisPath}/fragmented.webp`,
        categories: ['Survival', 'Sci-Fi', 'Open World', 'Crafting', 'Action'],
        gamePurchaseLink: 'https://store.steampowered.com/app/441790/Fragmented/',
    },
    'kerbal-space-program': {
        video: 'https://www.youtube.com/embed/aAa9Ao26gtM',
        image: `${thisPath}/kerbal-space-program.webp`,
        categories: ['Simulation', 'Space', 'Sandbox', 'Physics', 'Science'],
        gamePurchaseLink: 'https://store.steampowered.com/app/220200/Kerbal_Space_Program/',
    },
    'path-of-titans': {
        video: 'https://www.youtube.com/embed/XxRbo2luaow',
        image: `${thisPath}/path-of-titans.webp`,
        categories: ['Dinosaurs', 'MMO', 'Survival', 'Open World', 'Simulation'],
        gamePurchaseLink: 'https://alderongames.com/store/path-of-titans',
    },
    craftopia: {
        video: 'https://www.youtube.com/embed/lGy9DO3ZVyk',
        image: `${thisPath}/craftopia.webp`,
        categories: [
            'Open World',
            'Survival',
            'Crafting',
            'Building',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1307550/Craftopia/',
    },
    'ground-branch': {
        video: 'https://www.youtube.com/embed/SgMQiJG5CVk',
        image: `${thisPath}/ground-branch.webp`,
        categories: [
            'Tactical',
            'Shooter',
            'First-Person',
            'Multiplayer',
            'Realistic',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/16900/GROUND_BRANCH/',
    },
    'day-of-defeat': {
        video: 'https://www.youtube.com/embed/ACckk4o8Z-U',
        image: `${thisPath}/day-of-defeat.webp`,
        categories: ['Action', 'WWII', 'Shooter', 'Multiplayer', 'Team-Based'],
        gamePurchaseLink: 'https://store.steampowered.com/app/30/Day_of_Defeat/',
    },
    'battle-cry-of-freedom': {
        video: 'https://www.youtube.com/embed/8TUxKEyUwy4',
        image: `${thisPath}/battle-cry-of-freedom.webp`,
        categories: [
            'Action',
            'Simulation',
            'Strategy',
            'Historical',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1358710/Battle_Cry_of_Freedom/',
    },
    'no-more-room-in-hell': {
        video: 'https://www.youtube.com/embed/SeYo7idrYlo',
        image: `${thisPath}/no-more-room-in-hell.webp`,
        categories: [
            'Zombie',
            'Survival Horror',
            'Multiplayer',
            'Free to Play',
            'Shooter',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/224260/No_More_Room_in_Hell/',
    },
    rageMP: {
        video: 'https://www.youtube.com/embed/gs_2zVSDLzw',
        image: `${thisPath}/rageMP.webp`,
        categories: [
            'Mod',
            'Multiplayer',
            'Open World',
            'Custom Servers',
            'Action',
        ],
        gamePurchaseLink: 'https://rage.mp/',
    },
    'rising-storm-2-vietnam': {
        video: 'https://www.youtube.com/embed/YqyeNcNKxXs',
        image: `${thisPath}/rising-storm-2-vietnam.webp`,
        categories: ['FPS', 'War', 'Multiplayer', 'Tactical', 'Shooter'],
        gamePurchaseLink: 'https://store.steampowered.com/app/418460/Rising_Storm_2_Vietnam/',
    },
    'rising-world': {
        video: 'https://www.youtube.com/embed/BgRBFw39Ovs',
        image: `${thisPath}/rising-world.webp`,
        categories: ['Sandbox', 'Survival', 'Open World', 'Building', 'Crafting'],
        gamePurchaseLink: 'https://store.steampowered.com/app/324080/Rising_World/',
    },
    'san-andreas-multiplayer': {
        video: 'https://www.youtube.com/embed/f2nG7o4PEM4',
        image: `${thisPath}/san-andreas-multiplayer.webp`,
        categories: ['Multiplayer', 'Action', 'Open World', 'Mod', 'Online'],
        gamePurchaseLink: 'https://www.sa-mp.com/',
    },
    starbound: {
        video: 'https://www.youtube.com/embed/-O6PUh3reG0',
        image: `${thisPath}/starbound.webp`,
        categories: ['Adventure', 'Sandbox', 'Space', 'Building', 'Exploration'],
        gamePurchaseLink: 'https://store.steampowered.com/app/211820/Starbound/',
    },
    stationeers: {
        video: 'https://www.youtube.com/embed/BA7MnFZDe_U',
        image: `${thisPath}/stationeers.webp`,
        categories: ['Space', 'Simulation', 'Survival', 'Sandbox', 'Building'],
        gamePurchaseLink: 'https://store.steampowered.com/app/544550/Stationeers/',
    },
    subsistence: {
        video: 'https://www.youtube.com/embed/r2wXF28xf4g',
        image: `${thisPath}/subsistence.webp`,
        categories: ['Survival', 'Open World', 'Crafting', 'Action', 'Building'],
        gamePurchaseLink: 'https://store.steampowered.com/app/418030/Subsistence/',
    },
    subnautica: {
        video: 'https://www.youtube.com/embed/r2wXF28xf4g',
        image: `${thisPath}/subsistence.webp`,
        categories: ['Survival', 'Open World', 'Crafting', 'Action', 'Building'],
        gamePurchaseLink: 'https://store.steampowered.com/app/418030/Subsistence/',
    },
    terraria: {
        video: 'https://www.youtube.com/embed/w7uOhFTrrq0',
        image: `${thisPath}/terraria.webp`,
        categories: ['Adventure', 'Sandbox', 'Building', 'Crafting', 'Exploration'],
        gamePurchaseLink: 'https://store.steampowered.com/app/105600/Terraria/',
    },
    'the-forest': {
        video: 'https://www.youtube.com/embed/ay3tDjnBziY',
        image: `${thisPath}/the-forest.webp`,
        categories: ['Survival', 'Horror', 'Open World', 'Adventure', 'Crafting'],
        gamePurchaseLink: 'https://store.steampowered.com/app/242760/The_Forest/',
    },
    'the-isle': {
        video: 'https://www.youtube.com/embed/1Dv9RRrW0UI',
        image: `${thisPath}/the-isle.webp`,
        categories: [
            'Survival',
            'Dinosaurs',
            'Multiplayer',
            'Open World',
            'Simulation',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/376210/The_Isle/',
    },
    'team-fortress-2': {
        video: 'https://www.youtube.com/embed/N7ZafWA2jd8',
        image: `${thisPath}/team-fortress-2.webp`,
        categories: [
            'Shooter',
            'Multiplayer',
            'Action',
            'Team-Based',
            'Free to Play',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/440/Team_Fortress_2/',
    },
    'terraria-mobile': {
        video: 'https://www.youtube.com/embed/2WSCmUQSrbI',
        image: `${thisPath}/terraria-mobile.webp`,
        categories: ['Adventure', 'Sandbox', 'Building', 'Crafting', 'Exploration'],
        gamePurchaseLink: 'https://play.google.com/store/apps/details?id=com.and.games505.TerrariaPaid&hl=en&gl=US',
    },
    valheim: {
        video: 'https://www.youtube.com/embed/liQLtCLq3tc',
        image: `${thisPath}/valheim.webp`,
        categories: ['Survival', 'Open World', 'Viking', 'Crafting', 'Co-op'],
        gamePurchaseLink: 'https://store.steampowered.com/app/892970/Valheim/',
    },
    ylands: {
        video: 'https://www.youtube.com/embed/Ief6LheNC2g',
        image: `${thisPath}/ylands.webp`,
        categories: ['Sandbox', 'Adventure', 'Survival', 'Building', 'Exploration'],
        gamePurchaseLink: 'https://store.steampowered.com/app/298610/Ylands/',
    },
    'risk-of-rain-2': {
        video: 'https://www.youtube.com/embed/Qwgq_9EOCTg',
        image: `${thisPath}/risk-of-rain-2.webp`,
        categories: [
            'Rogue-like',
            'Multiplayer',
            'Action',
            'Third-Person Shooter',
            'Co-op',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/632360/Risk_of_Rain_2/',
    },
    longvinter: {
        video: 'https://www.youtube.com/embed/GQmO52f26Ws',
        image: `${thisPath}/longvinter.webp`,
        categories: [
            'Survival',
            'Multiplayer',
            'Open World',
            'Building',
            'Sandbox',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1635450/Longvinter/',
    },
    remnants: {
        video: 'https://www.youtube.com/embed/9WQIAKGYquM',
        image: `${thisPath}/remnants.webp`,
        categories: ['Survival', 'Open World', 'Multiplayer', 'Crafting', 'Action'],
        gamePurchaseLink: 'https://store.steampowered.com/app/574180/Remnants/',
    },
    cryofall: {
        video: 'https://www.youtube.com/embed/ACq0MAI-VF4',
        image: `${thisPath}/cryofall.webp`,
        categories: ['Survival', 'Sci-Fi', 'Multiplayer', 'Sandbox', 'Crafting'],
        gamePurchaseLink: 'https://store.steampowered.com/app/829590/CryoFall/',
    },
    'baro-trauma': {
        video: 'https://www.youtube.com/embed/d6xmFNNRm48',
        image: `${thisPath}/baro-trauma.webp`,
        categories: ['Survival', 'Simulation', 'Co-op', 'Submarine', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/602960/Barotrauma/',
    },
    'primal-carnage': {
        video: 'https://www.youtube.com/embed/NAHpwK6qA4k',
        image: `${thisPath}/primal-carnage.webp`,
        categories: ['Action', 'Dinosaurs', 'Multiplayer', 'Shooter', 'Team-Based'],
        gamePurchaseLink: 'https://store.steampowered.com/app/215470/Primal_Carnage/',
    },
    'last-oasis': {
        video: 'https://www.youtube.com/embed/-Yd9nGbAGYI',
        image: `${thisPath}/last-oasis.webp`,
        categories: ['MMO', 'Survival', 'Open World', 'Crafting', 'Action'],
        gamePurchaseLink: 'https://store.steampowered.com/app/903950/Last_Oasis/',
    },
    stormworks: {
        video: 'https://www.youtube.com/embed/8OJcwCElxUw',
        image: `${thisPath}/stormworks.webp`,
        categories: ['Simulation', 'Sandbox', 'Building', 'Physics', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/573090/Stormworks_Build_and_Rescue/',
    },
    'v-rising': {
        video: 'https://www.youtube.com/embed/r9aaaHhajv0',
        image: `${thisPath}/v-rising.webp`,
        categories: ['Survival', 'Open World', 'Vampire', 'Action', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1604030/V_Rising/',
    },
    scum: {
        video: 'https://www.youtube.com/embed/r9aaaHhajv0',
        image: `${thisPath}/scum.webp`,
        categories: [
            'Survival',
            'Open World',
            'Multiplayer',
            'Realistic',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/513710/SCUM/',
    },
    'angels-fall-first': {
        video: 'https://www.youtube.com/embed/l73nvWCXzQE',
        image: `${thisPath}/angels-fall-first.webp`,
        categories: ['Action', 'Sci-Fi', 'Space', 'FPS', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/367270/Angels_Fall_First/',
    },
    'battlefield-2': {
        video: 'https://www.youtube.com/embed/CWWiNxwB2R4',
        image: `${thisPath}/battlefield-2.webp`,
        categories: ['Action', 'FPS', 'Multiplayer', 'Tactical', 'War'],
        gamePurchaseLink: 'https://www.cdkeysforgames.com/games/steam-games/battlefield-2/',
    },
    'battle-rush-2': {
        video: 'https://www.youtube.com/embed/JRkFhnJhf0U',
        image: `${thisPath}/battle-rush-2.webp`,
        categories: [
            'Action',
            'Multiplayer',
            'Shooter',
            'Strategy',
            'World War II',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/871990/BattleRush_2/',
    },
    chivalry: {
        video: 'https://www.youtube.com/embed/150hKZHpgLw',
        image: `${thisPath}/chivalry.webp`,
        categories: [
            'Action',
            'Multiplayer',
            'Medieval',
            'First-Person',
            'Melee Combat',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/219640/Chivalry_Medieval_Warfare/',
    },
    citadel: {
        video: 'https://www.youtube.com/embed/h-DY-Suwu9Y',
        image: `${thisPath}/citadel.webp`,
        categories: [
            'Adventure',
            'Action',
            'Fantasy',
            'Singleplayer',
            'Exploration',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1378290/The_Citadel/#:~:text=Aug%205%2C%202020,Go%20forth%2C%20Martyr.',
    },
    contagion: {
        video: 'https://www.youtube.com/embed/at1yHOVitHg',
        image: `${thisPath}/contagion.webp`,
        categories: [
            'Horror',
            'Survival',
            'Zombies',
            'Multiplayer',
            'First-Person Shooter',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/238430/Contagion/',
    },
    'colony-survival': {
        video: 'https://www.youtube.com/embed/QEZZB3AFRWw?si=678qYfJCh32ahZ8T',
        image: `${thisPath}/colony-survival.webp`,
        categories: [
            'Colony Sim',
            'Survival',
            'Base Building',
            'Building',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/366090/Colony_Survival/',
    },
    satisfactory: {
        video: 'https://www.youtube.com/embed/UIEnhYio0VQ',
        image: `${thisPath}/satisfactory.webp`,
        categories: [],
        gamePurchaseLink: 'https://store.steampowered.com/app/526870/Satisfactory/',
    },
    'no-one-survived': {
        video: 'https://www.youtube.com/embed/W94Zy39Wlbg?si=DRaxNsVuAiEvRzX_',
        image: `${thisPath}/no-one-survived.webp`,
        categories: ['Survival', 'Horror', 'First-Person', 'Action', 'Adventure'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1963370/No_One_Survived/',
    },
    enshrouded: {
        video: 'https://www.youtube.com/embed/qygf4aICf34?si=YyurC--dKO3Kn4eH',
        image: `${thisPath}/enshrouded.webp`,
        categories: [
            'Survival',
            'Base Building',
            'Open World',
            'Online Co-Op',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1203620/Enshrouded/',
    },
    palworld: {
        video: 'https://www.youtube.com/embed/LXhfwoPJfm0?si=uW7qLaIolxQiJJPi',
        image: `${thisPath}/palworld.webp`,
        categories: [
            'Multiplayer',
            'Creature Collector',
            'Open World',
            'Survival',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1623730/Palworld/',
    },
    necesse: {
        video: 'https://www.youtube.com/embed/jaU2DYTso0Q?si=VjrNXFHDUCawRO47',
        image: `${thisPath}/necesse.webp`,
        categories: [
            'Open World Survival Craft',
            'Survival',
            'Multiplayer',
            'Sandbox',
            'Open World',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1169040/Necesse/',
    },
    'kingdom-of-atham': {
        video: 'https://www.youtube.com/embed/kZ6oZAMnjt0?si=hkjxjH1s7Q0qofHg',
        image: `${thisPath}/kingdom-of-atham.webp`,
        categories: [
            'Exploration',
            'Hack and Slash',
            'Dungeon Crawler',
            'Sandbox',
            'PvE',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1676380/Kingdom_of_Atham_Crown_of_the_Champions/',
    },
    'dead-poly': {
        video: 'https://www.youtube.com/embed/tTM34sIEYfw?si=JlB1lUk1i8-D1Ujo',
        image: `${thisPath}/dead-poly.webp`,
        categories: [
            'Survival',
            'Zombies',
            'Open World',
            'Open World Survival Craft',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1621070/DeadPoly/',
    },
    'mx-bikes': {
        video: 'https://www.youtube.com/embed/xHcKkXNMJXQ?si=e1bU0OHROLx9aTc2',
        image: `${thisPath}/mx-bikes.webp`,
        categories: [
            'Motocross',
            'Motorbike',
            'Multiplayer',
            'Simulation',
            'Offroad',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/655500/MX_Bikes/',
    },
    'hogwarts-legacy': {
        video: 'https://www.youtube.com/embed/BtyBjOW8sGY?si=fRo_5Xwr8F4n-p4o',
        image: `${thisPath}/hogwarts-legacy.webp`,
        categories: ['Action', 'Adventure', 'RPG'],
        gamePurchaseLink: 'https://store.steampowered.com/sub/822364/',
    },
    'et-legacy': {
        video: 'https://www.youtube.com/embed/QlvxU8t0cyw?si=Rns0R2m8GsxAm07V',
        image: `${thisPath}/et-legacy.webp`,
        categories: ['Action', 'Indie', 'Multiplayer', 'Shooter', 'First-Person'],
        gamePurchaseLink: 'https://store.steampowered.com/app/1465660/ET_Legacy/',
    },
    'the-front': {
        video: 'https://www.youtube.com/embed/PzYwyhvxcTo?si=VjCCdVy6b_3mcxUB',
        image: `${thisPath}/the-front.webp`,
        categories: [
            'Action',
            'Indie',
            'Massively Multiplayer',
            'Simulation',
            'Early Access',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/2285150/The_Front/',
    },
    'half-life-2': {
        video: 'https://www.youtube.com/embed/SGVy01I_pmk?si=OvyzQfgxePxyvVsl',
        image: `${thisPath}/half-life-2.webp`,
        categories: ['FPS', 'Action', 'Sci-Fi', 'Classic', 'Singleplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/220/HalfLife_2/',
    },
    'small-land': {
        video: 'https://www.youtube.com/embed/xDgZpxDhij4?si=lNe-IKWFVHdzy6Ks',
        image: `${thisPath}/small-land.webp`,
        categories: ['Action', 'Adventure', 'Indie', 'RPG', 'Early Access'],
        gamePurchaseLink: 'https://store.steampowered.com/app/768200/Smalland_Survive_the_Wilds/',
    },
    humanitz: {
        video: '',
        image: `${thisPath}/humanitz.webp`,
        categories: [],
        gamePurchaseLink: '',
    },
    'reign-of-dwarf': {
        video: 'https://www.youtube.com/embed/A_3tG00VSU8?si=HFucpRr_SN_PpkX_',
        image: `${thisPath}/reign-of-dwarf.webp`,
        categories: [
            'Puzzle',
            'Puzzle Platformer',
            'Singleplayer',
            'Dog',
            'Casual',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1581480/HUMANITY/',
    },
    leap: {
        video: 'https://www.youtube.com/embed/nXQQDlTI89Q',
        image: `${thisPath}/leap.webp`,
        categories: [
            'Action',
            'Sci-fi',
            'First-Person',
            'FPS',
            'Casual',
            'Dark Comedy',
            'Military',
            'Parkour',
            'Flight',
            'Space',
            '3D',
            'Colorful',
            'Artificial Intelligence',
            'Realistic',
            'Post-apocalyptic',
            'Mars',
            'Arena Shooter',
            'Sandbox',
            'eSports',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/906930/LEAP/',
    },
    animalia: {
        video: 'https://www.youtube.com/embed/W_pWNRNQeig',
        image: `${thisPath}/animalia.webp`,
        categories: [
            'Action',
            'Adventure',
            'Indie',
            'Massively Multiplayer',
            'Simulation',
            'Strategy',
            'Early Access',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1364290/Animalia_Survival/',
    },
    'alien-swarm': {
        video: 'https://www.youtube.com/embed/4w9neFmPlTk',
        image: `${thisPath}/alien-swarm.webp`,
        categories: [
            'Free to Play',
            'Co-op',
            'Action',
            'Multiplayer',
            'Online Co-Op',
            'Aliens',
            'Shooter',
            'Sci-fi',
            'Top-Down',
            'Top-Down Shooter',
            'Survival',
            'Class-Based',
            'PvE',
            'Third Person',
            'Team-Based',
            'Third-Person Shooter',
            'Moddable',
            'Singleplayer',
            'Strategy',
            'Adventure',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/630/Alien_Swarm/',
    },
    'reactive-drop': {
        video: 'https://www.youtube.com/embed/ldf5AOCulQ4',
        image: `${thisPath}/reactive-drop.webp`,
        categories: [
            'Action',
            'Free to Play',
            'Twin Stick Shooter',
            'PvE',
            'FPS',
            'Aliens',
            'First-Person',
            'Top-Down Shooter',
            'Third-Person Shooter',
            'Third Person',
            'Class-Based',
            'PvP',
            'Shooter',
            'Level Editor',
            'Top-Down',
            'Procedural Generation',
            'Sci-fi',
            'Tactical',
            'Hacking',
            'Combat',
            'Moddable',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/563560/Alien_Swarm_Reactive_Drop/',
    },
    'american-truck-simulator': {
        video: 'https://www.youtube.com/embed/Yu32OisFgWI',
        image: `${thisPath}/american-truck-simulator.webp`,
        categories: [
            'Indie',
            'Simulation',
            'Automobile Sim',
            'Driving',
            'Open World',
            'Transportation',
            'Simulation',
            'America',
            'Realistic',
            'Moddable',
            'Relaxing',
            'Exploration',
            'Economy',
            'Management',
            'Atmospheric',
            'Immersive Sim',
            'Multiplayer',
            'Singleplayer',
            'Adventure',
            'Casual',
            'Family Friendly',
            'Level Editor',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/270880/American_Truck_Simulator/',
    },
    'ancestors-legacy': {
        video: 'https://www.youtube.com/embed/zCmyeSI6s0s',
        image: `${thisPath}/ancestors-legacy.webp`,
        categories: ['To be updated after checking Steam page directly'],
        gamePurchaseLink: 'https://store.steampowered.com/app/620590/Ancestors_Legacy/',
    },
    'ark-survival-of-the-fittest': {
        video: 'https://www.youtube.com/embed/',
        image: `${thisPath}/ark-survival-of-the-fittest.webp`,
        categories: [],
        gamePurchaseLink: '',
    },
    'arma-2': {
        video: 'https://www.youtube.com/embed/UgXq6z6-644',
        image: `${thisPath}/arma-2.webp`,
        categories: ['Military', 'Simulation', 'Strategy', 'Shooter'], // Categories are assumed based on the game's genre and must be verified on Steam.
        gamePurchaseLink: 'https://store.steampowered.com/app/33900/Arma_2/',
    },
    'battle-rush': {
        video: 'https://www.youtube.com/embed/sg_KS12yNGk',
        image: `${thisPath}/battle-rush.webp`,
        categories: [
            'Free to Play',
            'World War II',
            'Multiplayer',
            'FPS',
            'Survival',
            'Action',
            'Shooter',
            'Massively Multiplayer',
            'Adventure',
            'Indie',
            'Open World',
            'First-Person',
            'Crafting',
            'Singleplayer',
            'Zombies',
            'Battle Royale',
            'Early Access',
            'Strategy',
            'Horror',
            'Co-op',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/734580/BattleRush/',
    },
    'call-of-duty': {
        video: 'https://www.youtube.com/embed/blkd6iIouSA',
        image: `${thisPath}/call-of-duty.webp`,
        categories: [
            'FPS',
            'World War II',
            'Action',
            'Classic',
            'Singleplayer',
            'Multiplayer',
            'Shooter',
            'War',
            'Military',
            'First-Person',
            'Historical',
            'Atmospheric',
            'Linear',
            'America',
            'Story Rich',
            'Old School',
            'Realistic',
            'Difficult',
            'Co-op',
            'Nostalgia',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/2620/Call_of_Duty_2003/',
    },
    'black-ops-3': {
        video: 'https://www.youtube.com/embed/58Pspqx0XGs',
        image: `${thisPath}/black-ops-3.webp`,
        categories: [
            'Action',
            'Adventure',
            'Multiplayer',
            'Zombies',
            'Shooter',
            'First-Person',
            'Sci-Fi',
            'Singleplayer',
            'Co-op',
            'Story Rich',
            'FPS',
            'Military',
            'Horror',
            'Futuristic',
            'Strategy',
            'Survival',
            'War',
            'Open World',
            'Tactical',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/311210/Call_of_Duty_Black_Ops_III/',
    },
    'call-of-duty-united-offensive': {
        video: 'https://www.youtube.com/embed/pMZ0aGYpadA',
        image: `${thisPath}/call-of-duty-united-offensive.webp`,
        categories: [
            'Action',
            'World War II',
            'FPS',
            'Singleplayer',
            'Multiplayer',
            'Shooter',
            'Military',
            'Classic',
            'Historical',
            'War',
            'First-Person',
            'Atmospheric',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/2640/Call_of_Duty_United_Offensive/',
    },
    'counter-strike-condition-zero': {
        video: 'https://www.youtube.com/embed/wwkrrxcfTxM',
        image: `${thisPath}/counter-strike-condition-zero.webp`,
        categories: [
            'Action',
            'FPS',
            'Shooter',
            'Multiplayer',
            'First-Person',
            'Singleplayer',
            'Classic',
            'Tactical',
            'Team-Based',
            'Competitive',
            'Military',
            'Online Co-Op',
            'Strategy',
            'Adventure',
            'Survival',
            'Atmospheric',
            'Old School',
            'Open World',
            'Dark',
            'Simulation',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/80/CounterStrike_Condition_Zero/',
    },
    'crafting-dead': {
        video: 'https://www.youtube.com/embed/6OwJVu3ChUI',
        image: `${thisPath}/crafting-dead.webp`,
        categories: [
            'Survival',
            'Zombies',
            'Multiplayer',
            'Post-apocalyptic',
            'Adventure',
            'Action',
            'Open World',
            'Indie',
            'Voxel',
            'Pixel Graphics',
            'Early Access',
            'Crafting',
            'Base Building',
            'PvP',
            'Survival Horror',
            'Action-Adventure',
            'Looter Shooter',
            'First-Person',
            'Exploration',
            'Arcade',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/248610/Crafting_Dead/',
    },
    crea: {
        video: 'https://www.youtube.com/embed/BRD0AzFz7o0?si=iGz58kp_fImOPkA0', // Placeholder, specific video ID needed
        image: `${thisPath}/crea.webp`,
        categories: [
            'Open World Survival Craft',
            'Sandbox',
            'Crafting',
            'RPG',
            '2D',
            'Adventure',
            'Survival',
            'Indie',
            'Multiplayer',
            'Action',
            'Building',
            'Pixel Graphics',
            'Open World',
            'Co-op',
            'Side Scroller',
            'Moddable',
            'Platformer',
            'Singleplayer',
            'Memes',
            'Great Soundtrack',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/280520/Crea/',
    },
    'dark-messiah': {
        video: 'https://www.youtube.com/embed/',
        image: `${thisPath}/dark-messiah.webp`,
        categories: [],
        gamePurchaseLink: '',
    },
    'day-of-infamy': {
        video: 'https://www.youtube.com/playlist?list=PLExwdM8PQ6HfIhqoUDLk0Ejxx7BHkeCLG',
        image: `${thisPath}/day-of-infamy.webp`,
        categories: [
            'World War II',
            'FPS',
            'War',
            'Multiplayer',
            'Realistic',
            'Shooter',
            'Action',
            'Military',
            'Singleplayer',
            'Tactical',
            'First-Person',
            'Strategy',
            'Historical',
            'Co-op',
            'Gore',
            'Indie',
            'Simulation',
            'Online Co-Op',
            'Open World',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/447820/Day_of_Infamy',
    },
    'days-of-war': {
        video: 'https://www.youtube.com/embed/151teiiNUAQ?si=15lK_xrclCkBPe5C', // Placeholder, specific video ID needed
        image: `${thisPath}/days-of-war.webp`,
        categories: [
            'Action',
            'War',
            'World War II',
            'Indie',
            'Shooter',
            'FPS',
            'Violent',
            'First-Person',
            'Multiplayer',
            'Early Access',
            'Historical',
            'Mature',
            'Gore',
            'Realistic',
            'Singleplayer',
            'Military',
            'Team-Based',
            'Classic',
            'Class-Based',
            'Level Editor',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/454350/Days_of_War_Definitive_Edition/',
    },
    'death-match-classic': {
        video: 'https://www.youtube.com/embed/T6n_1eAC5yM',
        image: `${thisPath}/death-match-classic.webp`,
        categories: ['First-Person Shooter', 'Multiplayer', 'Action', 'Classic'],
        gamePurchaseLink: 'https://store.steampowered.com/app/40/Deathmatch_Classic/',
    },
    'dino-d-day': {
        video: 'https://www.youtube.com/embed/cJI3P-dk9Zk',
        image: `${thisPath}/dino-d-day.webp`,
        categories: [
            'Action',
            'Multiplayer',
            'Shooter',
            'World War II',
            'Dinosaurs',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/70000/Dino_DDay/',
    },
    draconia: {
        video: 'https://www.youtube.com/embed/E9V3QQyO-nY',
        image: `${thisPath}/draconia.webp`,
        categories: [
            'Survival',
            'Open World',
            'RPG',
            'Dragons',
            'Indie',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1295900/Draconia/',
    },
    dysterra: {
        video: 'https://www.youtube.com/embed/-UC-vph6Qc8',
        image: `${thisPath}/dysterra.webp`,
        categories: [
            'Survival',
            'Sci-Fi',
            'First-Person Shooter',
            'PvP',
            'PvE',
            'Open World',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1527890/Dysterra/',
    },
    dystopia: {
        video: 'https://www.youtube.com/embed/KE2UdIrTLac?si=jiHso75Oj5SgR4V_',
        image: `${thisPath}/dystopia.webp`,
        categories: [],
        gamePurchaseLink: '',
    },
    'eden-star': {
        video: 'https://www.youtube.com/embed/[YouTube Video ID]', // Specific video ID needed
        image: `${thisPath}/eden-star.webp`,
        categories: [
            'Sci-fi',
            'Action',
            'First-Person',
            'Early Access',
            'Strategy',
            'Sandbox',
            'Open World',
            'Adventure',
            'Indie',
            'Building',
            'Shooter',
            'Multiplayer',
            'Singleplayer',
            'Physics',
            'Tower Defense',
            'FPS',
            'Space',
            'Co-op',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/259570/EDEN_STAR/',
    },
    'fistful-of-frags': {
        video: 'https://www.youtube.com/embed/hX6p5l0eXqQ',
        image: `${thisPath}/fistful-of-frags.webp`,
        categories: ['Action', 'Shooter', 'Multiplayer', 'Western'],
        gamePurchaseLink: 'https://store.steampowered.com/app/265630/Fistful_of_Frags/',
    },
    'frozen-flame': {
        video: 'https://www.youtube.com/embed/qhOehndPpHw',
        image: `${thisPath}/frozen-flame.webp`,
        categories: [
            'Open World',
            'Survival',
            'RPG',
            'Multiplayer',
            'Fantasy',
            'Action',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1134020/Frozen_Flame/',
    },
    'hanako-honor-and-blade': {
        video: 'https://www.youtube.com/embed/24Ezkf3qUyE',
        image: `${thisPath}/hanako-honor-and-blade.webp`,
        categories: [
            'Action',
            'Multiplayer',
            'Samurai',
            'Hack and Slash',
            'Early Access',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/349510/Hanako_Honor__Blade/',
    },
    'holdfast-nations-at-war': {
        video: 'https://www.youtube.com/embed/Pj_BmN1nkL8',
        image: `${thisPath}/holdfast-nations-at-war.webp`,
        categories: [
            'Action',
            'Multiplayer',
            'Historical',
            'Strategy',
            'Shooter',
            'Napoleonic',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/589290/Holdfast_Nations_At_War/',
    },
    icarus: {
        video: 'https://www.youtube.com/embed/mwZ_hVuPJk0',
        image: `${thisPath}/icarus.webp`,
        categories: [
            'Survival',
            'Open World Survival Craft',
            'Multiplayer',
            'Base Building',
            'Online Co-Op',
            'Crafting',
            'Exploration',
            'Open World',
            'Building',
            'Co-op',
            'First-Person',
            'PvE',
            'Space',
            'Realistic',
            'Mining',
            'Third Person',
            'Sandbox',
            'Adventure',
            'Nature',
            'Science',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1149460/ICARUS',
    },
    'isles-of-yore': {
        video: 'https://www.youtube.com/embed/F4u-Vo47wpc',
        image: `${thisPath}/isles-of-yore.webp`,
        categories: [
            'Open World',
            'Dinosaurs',
            'PvE',
            'PvP',
            'Immersive Sim',
            'Multiplayer',
            'Atmospheric',
            'Survival',
            'Cinematic',
            'Character Action Game',
            'Action',
            'Adventure',
            'Simulation',
            'Sandbox',
            'Realistic',
            'Mystery',
            'Nature',
            'Sci-fi',
            'Early Access',
            'Survival Horror',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1049890/Isles_of_Yore',
    },
    'just-cause-2': {
        video: 'https://www.youtube.com/embed/b_dTmXyhUjM',
        image: `${thisPath}/just-cause-2.webp`,
        categories: [
            'Third Person Shooter',
            'Sandbox',
            'Open World',
            'Action',
            'Adventure',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/8190/Just_Cause_2/',
    },
    'just-cause-3': {
        video: 'https://www.youtube.comembed/m1wRn6cA0OE',
        image: `${thisPath}/just-cause-3.webp`,
        categories: [
            'Third Person Shooter',
            'Sandbox',
            'Open World',
            'Action',
            'Adventure',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/225540/Just_Cause_3/',
    },
    karagon: {
        video: 'https://www.youtube.com/embed/r3HfkWS-yWA',
        image: `${thisPath}/karagon.webp`,
        categories: [
            'Open World Survival Craft',
            'Survival',
            'FPS',
            'Looter Shooter',
            'Crafting',
            'Open World',
            'Shooter',
            'Action-Adventure',
            'Action RPG',
            'Exploration',
            'Base Building',
            'Online Co-Op',
            'Adventure',
            'RPG',
            'Action',
            'Building',
            'PvE',
            'Multiplayer',
            'Singleplayer',
            'Creature Collector',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1818610/Karagon_Survival_Robot_Riding_FPS/',
    },
    'killing-floor': {
        video: 'https://www.youtube.com/embed/63GR3m6LPkQ',
        image: `${thisPath}/killing-floor.webp`,
        categories: [
            'Action',
            'Co-op',
            'Horror',
            'Survival',
            'First-Person Shooter',
            'Multiplayer',
            'Zombies',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1250/Killing_Floor/',
    },
    'killing-floor-2': {
        video: 'https://www.youtube.com/embed/-gkW8HlaSoM',
        image: `${thisPath}/killing-floor-2.webp`,
        categories: [
            'Action',
            'Zombies',
            'Co-op',
            'FPS',
            'Multiplayer',
            'Shooter',
            'Horror',
            'Survival',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/232090/Killing_Floor_2/',
    },
    'left-4-dead': {
        video: 'https://www.youtube.com/embed/7ktJ3mGZIlc',
        image: `${thisPath}/left-4-dead.webp`,
        categories: [
            'Zombies',
            'Co-op',
            'Multiplayer',
            'FPS',
            'Action',
            'Horror',
            'Shooter',
            'Online Co-Op',
            'Survival',
            'Team-Based',
            'First-Person',
            'Post-apocalyptic',
            'Singleplayer',
            'Atmospheric',
            'Adventure',
            'Moddable',
            'Tactical',
            'Replay Value',
            'Competitive',
            'Female Protagonist',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/500/Left_4_Dead/',
    },
    'medal-of-honor-spearhead': {
        video: 'https://www.youtube.com/embed/uSKeTgglH7k',
        image: `${thisPath}/medal-of-honor-spearhead.webp`,
        categories: [
            'Shooter',
            'FPP',
            'Historical',
            'Classic',
            'First-Person',
            'Historical',
            'FPS',
            'Shooter',
            'World War II',
        ],
        gamePurchaseLink: 'https://www.gog.com/game/medal_of_honor_allied_assault_war_chest',
    },
    'minecraft-pocket-edition': {
        video: 'https://www.youtube.com/embed/UGTjyJsXw14?si=cHYVND0RxUS8RLp7',
        image: `${thisPath}/minecraft-pocket-edition.webp`,
        categories: [],
        gamePurchaseLink: '',
    },
    'military-conflict-vietnam': {
        video: 'https://www.youtube.com/embed/p7nNtXlTOOM?si=2ML_OPF1j6tlOnW7',
        image: `${thisPath}/military-conflict-vietnam.webp`,
        categories: [
            'Action',
            'Shooter',
            'Arcade',
            'FPS',
            'First-Person',
            'Military',
            'Realistic',
            'War',
            'Multiplayer',
            'Early Access',
            'PvP',
            'Team-Based',
            'Violent',
            'Gore',
            'Historical',
            'Singleplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1012110/Military_Conflict_Vietnam/',
    },
    'medal-of-honor-allied-assault': {
        video: 'https://www.youtube.com/embed/RCGkY0s0WgU?si=4a18VwCHLUlRulZ5',
        image: `${thisPath}/medal-of-honour-allied-assault.webp`,
        categories: [
            'Shooter',
            'FPP',
            'Historical',
            'Classic',
            'First-Person',
            'World War II',
        ],
        gamePurchaseLink: 'https://www.gog.com/game/medal_of_honor_allied_assault_war_chest',
    },
    'mount-and-blade-2-bannerlord': {
        video: 'https://www.youtube.com/embed/q6oH5cW7PaA?si=A-hvi07tD8Xv_DOG',
        image: `${thisPath}/mount-and-blade-2-bannerlord.webp`,
        categories: [
            'Action',
            'RPG',
            'Strategy',
            'Simulation',
            'Open World',
            'Medieval',
            'Sandbox',
            'Multiplayer',
            'Singleplayer',
            'Early Access',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/261550/Mount__Blade_II_Bannerlord/',
    },
    'night-of-the-dead': {
        video: 'https://www.youtube.com/embed/xp-zcudwxnc?si=A-hvi07tD8Xv_DOG', // Use an actual video URL
        image: `${thisPath}/night-of-the-dead.webp`,
        categories: [
            'Action',
            'Adventure',
            'Indie',
            'Simulation',
            'Survival',
            'Zombies',
            'Base Building',
            'Multiplayer',
            'Open World',
            'Early Access',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1377380/Night_of_the_Dead/',
    },
    'nuclear-dawn': {
        video: 'https://www.youtube.com/embed/JI1WvXYmBPY',
        image: `${thisPath}/nuclear-dawn.webp`,
        categories: [
            'Action',
            'Strategy',
            'FPS',
            'Multiplayer',
            'Post-apocalyptic',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/17710/Nuclear_Dawn/',
    },
    orelight: {
        video: 'https://www.youtube.com/embed/9Znl5S7v5zM',
        image: `${thisPath}/orelight.webp`,
        categories: [
            'Action',
            'RPG',
            'Indie',
            'Sandbox',
            'Multiplayer',
            'Singleplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/520800/Orelight/',
    },
    'out-of-reach': {
        video: 'https://www.youtube.com/embed/Bo0yTAvG04A',
        image: `${thisPath}/out-of-reach.webp`,
        categories: [
            'Survival',
            'Adventure',
            'Open World',
            'Multiplayer',
            'Singleplayer',
            'Sandbox',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/327090/Out_of_Reach/',
    },
    'outpost-zero': {
        video: 'https://www.youtube.com/embed/GDLmOFTFQhA',
        image: `${thisPath}/outpost-zero.webp`,
        categories: [
            'Action',
            'Strategy',
            'Survival',
            'Base Building',
            'Multiplayer',
            'Sci-Fi',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/677240/Outpost_Zero/',
    },
    'planet-explorers': {
        video: 'https://www.youtube.com/embed/lXZBQoU2ZAY',
        image: `${thisPath}/planet-explorers.webp`,
        categories: [
            'Adventure',
            'RPG',
            'Sandbox',
            'Open World',
            'Sci-Fi',
            'Singleplayer',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/237870/Planet_Explorers/',
    },
    'portal-knights': {
        video: 'https://www.youtube.com/embed/pKwi8ogmnCM',
        image: `${thisPath}/portal-knights.webp`,
        categories: [
            'Action',
            'Adventure',
            'RPG',
            'Sandbox',
            'Multiplayer',
            'Singleplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/374040/Portal_Knights/',
    },
    'primal-carnage-extinction': {
        video: 'https://www.youtube.com/embed/TXPQnJyfXk4',
        image: `${thisPath}/primal-carnage-extinction.webp`,
        categories: ['Action', 'Indie', 'Multiplayer', 'Dinosaurs', 'Shooter'],
        gamePurchaseLink: 'https://store.steampowered.com/app/321360/Primal_Carnage_Extinction/',
    },
    'primal-earth': {
        video: 'https://www.youtube.com/embed/LkUvTtcDVjM',
        image: `${thisPath}/primal-earth.webp`,
        categories: [
            'Action',
            'Adventure',
            'Survival',
            'Open World',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/349070/Primal_Earth/',
    },
    'project-5-sightseer': {
        video: 'https://www.youtube.com/embed/3lt1IgxW10g',
        image: `${thisPath}/project-5-sightseer.webp`,
        categories: [
            'Adventure',
            'Indie',
            'Simulation',
            'Open World',
            'Multiplayer',
            'Exploration',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/761460/Project_5_Sightseer/',
    },
    'quake-3-arena': {
        video: 'https://www.youtube.com/embed/JNG-KnOFxKk',
        image: `${thisPath}/quake-3-arena.webp`,
        categories: ['Action', 'FPS', 'Multiplayer', 'Classic'],
        gamePurchaseLink: 'https://store.steampowered.com/app/2200/Quake_III_Arena/',
    },
    'quake-4': {
        video: 'https://www.youtube.com/embed/SE3NZBuZxyg',
        image: `${thisPath}/quake-4.webp`,
        categories: ['Action', 'FPS', 'Sci-Fi', 'Singleplayer', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/2210/Quake_IV/',
    },
    'quake-live': {
        video: 'https://www.youtube.com/embed/NNVFezjNZaU',
        image: `${thisPath}/quake-live.webp`,
        categories: ['Action', 'FPS', 'Multiplayer', 'Free to Play', 'Competitive'],
        gamePurchaseLink: 'https://store.steampowered.com/app/282440/Quake_Live/',
    },
    'quake-wars-enemy-territory': {
        video: 'https://www.youtube.com/embed/g0iF0I5Luys',
        image: `${thisPath}/quake-wars-enemy-territory.webp`,
        categories: ['Action', 'FPS', 'Multiplayer', 'Sci-Fi', 'Tactical'],
        gamePurchaseLink: 'https://store.steampowered.com/app/10090/Enemy_Territory_Quake_Wars/',
    },
    'reign-of-kings': {
        video: 'https://www.youtube.com/embed/GFP8SH6y8n4',
        image: `${thisPath}/reign-of-kings.webp`,
        categories: [
            'Action',
            'Adventure',
            'Survival',
            'Sandbox',
            'Medieval',
            'Multiplayer',
            'Open World',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/344760/Reign_Of_Kings/',
    },
    'red-orchestra-2': {
        video: 'https://www.youtube.com/embed/eNdl0UrdxgI',
        image: `${thisPath}/red-orchestra-2.webp`,
        categories: [
            'Action',
            'FPS',
            'World War II',
            'Multiplayer',
            'Tactical',
            'Realistic',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/35450/Red_Orchestra_2_Heroes_of_Stalingrad_with_Rising_Storm/',
    },
    'renegade-x': {
        video: 'https://www.youtube.com/embed/TOeHRih7HPk',
        image: `${thisPath}/renegade-x.webp`,
        categories: ['Action', 'Strategy', 'Shooter', 'Multiplayer', 'Tactical'],
        gamePurchaseLink: 'https://renegade-x.com/',
    },
    'return-to-castle-wolfenstein': {
        video: 'https://www.youtube.com/embed/Cy4j2LllCzg',
        image: `${thisPath}/return-to-castle-wolfenstein.webp`,
        categories: [
            'Action',
            'FPS',
            'World War II',
            'Singleplayer',
            'Multiplayer',
            'Classic',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/9010/Return_to_Castle_Wolfenstein/',
    },
    'road-to-eden': {
        video: 'https://www.youtube.com/embed/4H3ocOF3wP8',
        image: `${thisPath}/road-to-eden.webp`,
        categories: [
            'Action',
            'Adventure',
            'Survival',
            'Open World',
            'Multiplayer',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1035540/Road_to_Eden/',
    },
    'skyrim-together-reborn': {
        video: 'https://www.youtube.com/embed/TXPQnJyfXk4',
        image: `${thisPath}/skyrim-together-reborn.webp`,
        categories: ['Action', 'RPG', 'Adventure', 'Multiplayer', 'Mod'],
        gamePurchaseLink: 'https://www.nexusmods.com/skyrimspecialedition/mods/69993',
    },
    'sniper-elite-v2': {
        video: 'https://www.youtube.com/embed/Cy4j2LllCzg',
        image: `${thisPath}/sniper-elite-v2.webp`,
        categories: [
            'Action',
            'Shooter',
            'World War II',
            'Third-Person Shooter',
            'Tactical',
            'Singleplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/63380/Sniper_Elite_V2/',
    },
    'sniper-elite-v2-remastered': {
        video: 'https://www.youtube.com/embed/2z3ce3NZTss',
        image: `${thisPath}/sniper-elite-v2-remastered.webp`,
        categories: [
            'Action',
            'Shooter',
            'World War II',
            'Third-Person Shooter',
            'Tactical',
            'Singleplayer',
            'Remaster',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/728740/Sniper_Elite_V2_Remastered/',
    },
    'sniper-elite-4': {
        video: 'https://www.youtube.com/embed/9XZErh_oVfg',
        image: `${thisPath}/sniper-elite-4.webp`,
        categories: [
            'Action',
            'Shooter',
            'World War II',
            'Third-Person Shooter',
            'Tactical',
            'Singleplayer',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/312660/Sniper_Elite_4/',
    },
    'solace-crafting': {
        video: 'https://www.youtube.com/embed/kYsA9j10dGs',
        image: `${thisPath}/solace-crafting.webp`,
        categories: [
            'RPG',
            'Sandbox',
            'Survival',
            'Crafting',
            'Open World',
            'Indie',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/670390/Solace_Crafting/',
    },
    'star-wars-jedi-knight': {
        video: 'https://www.youtube.com/embed/lXZBQoU2ZAY',
        image: `${thisPath}/star-wars-jedi-knight.webp`,
        categories: [
            'Action',
            'Adventure',
            'Sci-Fi',
            'Shooter',
            'Singleplayer',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/32380/STAR_WARS_Jedi_Knight_Dark_Forces_II/',
    },
    'star-wars-jedi-knight-2-jedi-outcast': {
        video: 'https://www.youtube.com/embed/WZ1G4FFq1r0',
        image: `${thisPath}/star-wars-jedi-knight-2-jedi-outcast.webp`,
        categories: [
            'Action',
            'Adventure',
            'Sci-Fi',
            'Shooter',
            'Singleplayer',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/6030/STAR_WARS_Jedi_Knight_II_Jedi_Outcast/',
    },
    starmade: {
        video: 'https://www.youtube.com/embed/4_UuPEMhgA8',
        image: `${thisPath}/starmade.webp`,
        categories: [
            'Simulation',
            'Sandbox',
            'Sci-Fi',
            'Building',
            'Open World',
            'Multiplayer',
            'Singleplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/244770/StarMade/',
    },
    'survive-the-nights': {
        video: 'https://www.youtube.com/embed/dS6ixC9udMY',
        image: `${thisPath}/survive-the-nights.webp`,
        categories: [
            'Survival',
            'Horror',
            'Multiplayer',
            'Open World',
            'Zombies',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/386180/Survive_the_Nights/',
    },
    'swords-n-magic-and-stuff': {
        video: 'https://www.youtube.com/embed/9Znl5S7v5zM',
        image: `${thisPath}/swords-n-magic-and-stuff.webp`,
        categories: [
            'RPG',
            'Adventure',
            'Indie',
            'Multiplayer',
            'Open World',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/810040/Swords__Magic_and_Stuff/',
    },
    'the-black-death': {
        video: 'https://www.youtube.com/embed/UQqfMSiHeKk',
        image: `${thisPath}/the-black-death.webp`,
        categories: [
            'Survival',
            'Open World',
            'Multiplayer',
            'Medieval',
            'Sandbox',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/412450/The_Black_Death/',
    },
    'the-cenezoic-era': {
        video: 'https://www.youtube.com/embed/LkUvTtcDVjM',
        image: `${thisPath}/the-cenezoic-era.webp`,
        categories: [
            'Action',
            'Adventure',
            'Simulation',
            'Open World',
            'Crafting',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1519160/The_Cenezoic_Era/',
    },
    'sons-of-the-forest': {
        video: 'https://www.youtube.com/embed/GFP8SH6y8n4',
        image: `${thisPath}/sons-of-the-forest.webp`,
        categories: [
            'Survival',
            'Horror',
            'Adventure',
            'Open World',
            'Multiplayer',
            'Crafting',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1326470/Sons_Of_The_Forest/',
    },
    'the-ship': {
        video: 'https://www.youtube.com/embed/zkjJxYQCnIQ',
        image: `${thisPath}/the-ship.webp`,
        categories: [
            'Action',
            'Shooter',
            'Multiplayer',
            'First-Person',
            'Strategy',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/2400/The_Ship_Murder_Party/',
    },
    tinkertown: {
        video: 'https://www.youtube.com/embed/xMguGB1KZsQ',
        image: `${thisPath}/tinkertown.webp`,
        categories: [
            'Adventure',
            'Indie',
            'RPG',
            'Sandbox',
            'Multiplayer',
            'Building',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/1276660/Tinkertown/',
    },
    'unfortunate-spacemen': {
        video: 'https://www.youtube.com/embed/9S73pQtAA3A',
        image: `${thisPath}/unfortunate-spacemen.webp`,
        categories: [
            'Action',
            'Free to Play',
            'Indie',
            'Strategy',
            'Multiplayer',
            'Survival Horror',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/408900/Unfortunate_Spacemen/',
    },
    'unreal-tournament-2004': {
        video: 'https://www.youtube.com/embed/ZK4kHS7ZQsE',
        image: `${thisPath}/unreal-tournament-2004.webp`,
        categories: ['Action', 'FPS', 'Multiplayer', 'Sci-Fi', 'Classic'],
        gamePurchaseLink: 'https://store.steampowered.com/app/13230/Unreal_Tournament_2004/',
    },
    'hypercharge-unboxed': {
        video: 'https://www.youtube.com/embed/2z3ce3NZTss',
        image: `${thisPath}/hypercharge-unboxed.webp`,
        categories: ['Action', 'Indie', 'Shooter', 'Tower Defense', 'Multiplayer'],
        gamePurchaseLink: 'https://store.steampowered.com/app/613100/HYPERCHARGE_Unboxed/',
    },
    'vintage-story': {
        video: 'https://www.youtube.com/embed/kYsA9j10dGs',
        image: `${thisPath}/vintage-story.webp`,
        categories: [
            'Survival',
            'Sandbox',
            'Open World',
            'Crafting',
            'Indie',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://www.vintagestory.at/',
    },
    'scp-secret-laboratory': {
        video: 'https://www.youtube.com/embed/TlRzPoVuGAU',
        image: `${thisPath}/scp-secret-laboratory.webp`,
        categories: ['Action', 'Horror', 'Multiplayer', 'Free to Play', 'Survival'],
        gamePurchaseLink: 'https://store.steampowered.com/app/700330/SCP_Secret_Laboratory/',
    },
    'euro-truck-simulator-2': {
        video: 'https://www.youtube.com/embed/dS6ixC9udMY',
        image: `${thisPath}/euro-truck-simulator-2.webp`,
        categories: [
            'Simulation',
            'Driving',
            'Open World',
            'Multiplayer',
            'Singleplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/227300/Euro_Truck_Simulator_2/',
    },
    'stardew-valley': {
        video: 'https://www.youtube.com/embed/ot7uXNQskhs',
        image: `${thisPath}/stardew-valley.webp`,
        categories: [
            'RPG',
            'Simulation',
            'Farming',
            'Open World',
            'Indie',
            'Multiplayer',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/413150/Stardew_Valley/',
    },
    soulmask: {
        video: 'https://www.youtube.com/embed/Z3f4s9RHFwU', // Example YouTube video link for Soulmask
        image: `${thisPath}/soulmask.webp`, // Path to the Soulmask image
        categories: [
            'Action',
            'Adventure',
            'RPG',
            'Dark Fantasy',
            'Single Player',
            'Indie',
        ],
        gamePurchaseLink: 'https://store.steampowered.com/app/2646460/Soulmask/', // Actual Steam store link for Soulmask
    },
};
exports.default = VideoLinks;
