"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onlySmall = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../../styles/global");
const onlySmall = (0, react_1.css) `
  display: none;
  ${global_1.smallScreen} {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  ${global_1.tinyScreen} {
    width: 90%;
  }
`;
exports.onlySmall = onlySmall;
