"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageTitle = exports.pageContainer = exports.pageColumnText = exports.pageText = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../global");
exports.pageText = (0, react_1.css) `
  background-color: #151443;
  margin-top: 1em;
  padding: 1em;
  border-radius: 20px;
  font-size: 1em;
  text-align: left;
  margin-left: 1em;

  ul {
    margin-left: 1em;
    list-style-type: disc;
  }

  ul ul {
    list-style-type: disc;
    margin-left: 1em;

    ${global_1.smallScreen} {
      padding: 0;
      width: 100%;
      text-decoration: none;

      p {
        font-weight: bold;
      }
    }
  }

  a {
    text-decoration: none;
    color: grey;
  }

  a:hover,
  a:link,
  a:visited {
    color: grey;
  }

  ${global_1.smallScreen} {
    text-align: center;

    ul {
      padding-left: 0;
    }

    ul > li {
      list-style-type: none;
    }

    column-count: 1;
  }
`;
exports.pageColumnText = (0, react_1.css) `
  background-color: #151443;
  margin-top: 1em;
  padding: 1em;
  border-radius: 20px;
  font-size: 1em;
  column-count: 3;

  ul {
    margin-left: 1em;
    list-style-type: disc;
  }

  ${global_1.smallScreen} {
    column-count: 1;
  }

  ul ul {
    list-style-type: disc;
    margin-left: 1em;

    ${global_1.smallScreen} {
      width: 100%;
    }

    text-align: left;
  }
`;
exports.pageContainer = (0, react_1.css) `
  margin: 1em auto;
  width: 80%;
  border-radius: 2em;
  min-height: 100vh;
  color: white;
  background-color: #151443;
  padding: 3em;

  ${global_1.smallScreen} {
    text-align: center;
    padding: 1em;

    ul ul {
      list-style-type: disc;
      margin-left: 1em;

      ${global_1.smallScreen} {
        width: 100%;
      }

      text-align: left;
    }
  }

  a {
    text-decoration: none;
    color: grey;
  }

  a:link,
  a:hover,
  a:visited {
    color: grey;
  }
`;
exports.pageTitle = (0, react_1.css) `
  text-align: center;
`;
