"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notInList = exports.notHighlighted = exports.highlighted = exports.displayGrid = exports.inActiveGame = exports.gameBannerImagesStyle = exports.flexxy = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
exports.flexxy = (0, react_1.css) `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em;
`;
exports.gameBannerImagesStyle = (0, react_1.css) `
  transition: all 0.2s ease-in-out;
  margin-left: 1%;
  margin-right: 1%;
  position: relative;
  z-index: 1;
  transform: scale(1);
  width: 150px;
  height: 86px;

  &:hover {
    position: relative;
    transform: scale(1.25);
    z-index: 2;
    opacity: 1;
    overflow: visible;
  }

  ${global_1.smallScreen} {
    width: 150px;
    height: 86px;
  }
`;
exports.inActiveGame = (0, react_1.css) `
  transition: all 0.2s ease-in-out;
  z-index: 0;
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
  opacity: 0.2;
  object-fit: contain;

  &:hover {
    position: relative;
    z-index: 0;
    opacity: 0.2;
    overflow: visible;
  }
`;
exports.displayGrid = (0, react_1.css) `
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  justify-content: center;
  cursor: pointer;
  align-content: center;
`;
exports.highlighted = (0, react_1.css) `
  padding: 0.8em;
  position: relative;
  opacity: 1;
  z-index: 1;
  transform: scale(1.25);
`;
exports.notHighlighted = (0, react_1.css) `
  padding: 0.8em;
  position: relative;
  opacity: 0.4;
  z-index: 0;
  transform: scale(0.5);
`;
exports.notInList = (0, react_1.css) `
  opacity: 0.2;

  &:hover {
    cursor: default;
    opacity: 0.2;
  }
`;
