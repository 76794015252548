"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideDescriptionButton = exports.introductionText = exports.introductionContainer = void 0;
const react_1 = require("@emotion/react");
// Define breakpoints if necessary
const mediumScreen = '@media (min-width: 768px)';
exports.introductionContainer = (0, react_1.css) `
  width: 80%;
  margin: 0 auto;
  background-color: #202020;
  color: whitesmoke;
  border-radius: 1.5rem;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
`;
exports.introductionText = (0, react_1.css) `
  padding: 1em;
  margin-bottom: 1em;
  font-size: 1.5em;
  ${mediumScreen} {
    font-size: 3em;
  }
`;
exports.hideDescriptionButton = (0, react_1.css) `
  transition: all 0.3s ease;
  width: 100%;
  padding: 1em 0;
  background-color: #3f51b5;
  border-radius: 0.5em;
  outline-style: none;
  outline-color: #3f51b5;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: #3f51ff;
    transform: translateY(1em);
  }
`;
