"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_twitter_embed_1 = require("react-twitter-embed");
const styles_1 = require("./styles");
const server = {
    'arkservers.io': 'arkserversio',
    'blue fang solutions, llc': 'bluefangservers',
    'host havoc ltd.': 'hosthavoc',
    'low.ms': 'lowmshosting',
    'nitrous networks': 'nitrousnetworks',
    pingperfect: 'pingperfect',
    serverblend: 'serverblend',
    shockbyte: 'shockbyte',
};
const TwitterContainer = ({ serverName }) => {
    const serverNameInTwitter = serverName.toLowerCase();
    let serverNameLookup = server[serverNameInTwitter];
    if (!serverNameLookup)
        return null;
    return ((0, jsx_runtime_1.jsx)("section", { css: styles_1.twitterContainerStyle, className: "twitterContainer", children: (0, jsx_runtime_1.jsx)("div", { className: "twitter-embed", children: (0, jsx_runtime_1.jsx)(react_twitter_embed_1.TwitterTimelineEmbed, { sourceType: "profile", screenName: serverNameLookup, options: { theme: 'dark', tweetLimit: 5 }, noHeader: true, noFooter: true, noBorders: true }) }) }));
};
exports.default = TwitterContainer;
