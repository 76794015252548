"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const styles_1 = require("./styles");
const ShowMoreHostButton = ({ defaultNumberOfServersShown, showHostTotal, currentHost, showAllHostsToggle, }) => {
    const showButton = () => {
        return currentHost === 'all' ? {} : { display: 'none' };
    };
    return ((0, jsx_runtime_1.jsx)("div", { children: currentHost === 'all' ? ((0, jsx_runtime_1.jsx)("button", { className: "show-servers", css: [styles_1.buttonStyling, showButton()], onClick: showAllHostsToggle, children: showHostTotal === defaultNumberOfServersShown
                ? 'Show more Servers'
                : 'Show less servers' })) : null }));
};
exports.default = ShowMoreHostButton;
