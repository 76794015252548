"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageInfo = exports.gameListImageStyle = exports.tinyDisplay = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const tinyDisplay = (0, react_1.css) `
  ${global_1.notTinyScreen} {
    flex-direction: column;
  }
`;
exports.tinyDisplay = tinyDisplay;
const gameListImageStyle = (0, react_1.css) `
  transition: all 0.2s ease-in-out;
  width: 150px;
  height: 82px;
  padding: 0.5em;
`;
exports.gameListImageStyle = gameListImageStyle;
const imageInfo = (0, react_1.css) `
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.5em;
  padding: 1em;
`;
exports.imageInfo = imageInfo;
