"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const styles_1 = require("../../styles");
const SteamContent = ({ item, index }) => {
    const content = item['content:encodedSnippet']
        .substring(0, 400)
        .replace(/\n ?\n+\t?/gm, '\n\n')
        .replace(/\t/g, '\n');
    return ((0, jsx_runtime_1.jsx)("div", { css: (0, styles_1.rssItem)(index), className: "rss-item", children: (0, jsx_runtime_1.jsxs)("div", { className: "rss-link-content", css: styles_1.rssContent, children: [(0, jsx_runtime_1.jsxs)("a", { css: styles_1.rssLink, className: "rss-link", href: item.link, children: [(0, jsx_runtime_1.jsx)("h2", { children: item.title }), (0, jsx_runtime_1.jsx)("p", { children: item.categories.join(', ') }), (0, jsx_runtime_1.jsx)("h3", { children: item.pubDate }), (0, jsx_runtime_1.jsx)("div", { className: "content", css: {
                                display: 'flex',
                                alignItems: 'space-between',
                                flexDirection: 'column',
                            } }), (0, jsx_runtime_1.jsx)("div", { css: { objectFit: 'cover' }, children: (0, jsx_runtime_1.jsx)("p", { css: { whiteSpace: 'pre-wrap' }, children: content }) })] }), (0, jsx_runtime_1.jsx)("div", { css: styles_1.readMoreLink, children: (0, jsx_runtime_1.jsx)("a", { href: item.url, children: "Click to read More" }) })] }) }));
};
exports.default = SteamContent;
