"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const selectStar = (count) => {
    switch (true) {
        case count < 0.3:
            return 'trustpilot-0-star';
        case count < 0.8:
            return 'trustpilot-0.5-star';
        case count < 1.3:
            return 'trustpilot-1-star';
        case count < 1.8:
            return 'trustpilot-1.5-star';
        case count < 2.3:
            return 'trustpilot-2-star';
        case count < 2.8:
            return 'trustpilot-2.5-star';
        case count < 3.3:
            return 'trustpilot-3-star';
        case count < 3.8:
            return 'trustpilot-3.5-star';
        case count < 4.3:
            return 'trustpilot-4-star';
        case count < 4.8:
            return 'trustpilot-4.5-star';
        default:
            return 'trustpilot-5-star';
    }
};
exports.default = selectStar;
