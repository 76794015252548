"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.footerColumnContainer = exports.footerStyle = exports.adminLinks = exports.adminLinks2 = exports.adminButtonLink = exports.logo = exports.footerLink = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const footerLink = (0, react_1.css) `
  text-decoration: none;
  margin: 0;
  text-align: center;
  color: inherit;
  ${global_1.smallScreen} {
    // border: 1px solid white;
  }
`;
exports.footerLink = footerLink;
const logo = (0, react_1.css) `
  width: 70%;
  margin: 0 auto;
  font-weight: bolder;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
exports.logo = logo;
const adminButtonLink = (0, react_1.css) `
  text-decoration: none;
  border-radius: 5px;
  background-color: grey;
  margin: 1em;
  padding: 1em;
  display: flex;
  align-items: center;
  color: #111111;
  &:hover {
    background-color: darkgrey;
  }
  text-align: center;
  ${global_1.smallScreen} {
    width: 50%;
  }
`;
exports.adminButtonLink = adminButtonLink;
const adminLinks2 = (0, react_1.css) `
  grid-area: column3;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    padding: 0;
    margin: 0;
  }
`;
exports.adminLinks2 = adminLinks2;
const adminLinks = (0, react_1.css) `
  color: inherit;
  flex-wrap: nowrap;
  justify-content: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  ${global_1.smallScreen} {
    flex-direction: column;
    align-items: center;
  }
`;
exports.adminLinks = adminLinks;
const footerStyle = (0, react_1.css) `
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 0.7vw;
  background-color: #202020;
  a:link,
  a:visited {
    text-decoration: none;
  }
  a:hover {
    color: #f1f1f1;
  }
  a:active {
    text-decoration: none;
  }
  ${global_1.smallScreen} {
    font-size: 1em;
  }
`;
exports.footerStyle = footerStyle;
const footerColumnContainer = (0, react_1.css) `
  color: inherit;
  box-sizing: border-box;
  padding: 1em 0;
  display: grid;
  margin: 0 auto;
  grid-gap: 4em;
  grid-template-columns: 1fr 1fr 1fr;
  width: 70%;
  text-align: left;
  grid-template-areas: "column1 column2 column3";
  a {
    text-decoration: none;
  }
  ${global_1.smallScreen} {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    width: 90%;
    grid-gap: 0;
  }
`;
exports.footerColumnContainer = footerColumnContainer;
