"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const react_i18next_1 = require("react-i18next");
const styles_1 = require("./styles");
const typedHooks_1 = require("../../app/hooks/typedHooks");
const Introduction = (props) => {
    const { currentGame, currentHost } = (0, typedHooks_1.useAppSelector)((state) => state.currentGameHost);
    const { t } = (0, react_i18next_1.useTranslation)();
    const backgroundImage = {
        backgroundImage: currentGame !== 'all' ? `url("/games/${currentGame}.jpg")` : undefined,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };
    let intro = currentGame === 'all'
        ? t('introductions.about-us-text')
        : t(`guides.description.${currentGame}`);
    const introArray = intro.split('\n');
    const plist = introArray.map((p, index) => (0, jsx_runtime_1.jsx)("p", { children: p }, index));
    const displaying = props.show
        ? { maxHeight: '3000px', transition: 'all 0.5s ease-in' }
        : { transition: 'all 0.5s ease-in', maxHeight: '0px', overflow: 'hidden' };
    if (!intro || currentHost !== 'all')
        return null;
    return ((0, jsx_runtime_1.jsxs)("section", { style: backgroundImage, css: styles_1.introductionContainer, className: "introduction", children: [(0, jsx_runtime_1.jsx)("button", { css: styles_1.hideDescriptionButton, style: { borderRadius: '4em' }, color: "primary", onClick: props.toggleShowIntro, children: props.show ? 'Hide Description' : 'Show Description' }), (0, jsx_runtime_1.jsx)("div", { css: displaying, className: "introduction-container", children: (0, jsx_runtime_1.jsx)("div", { className: "introduction-text", css: styles_1.introductionText, children: plist }) })] }));
};
exports.default = Introduction;
