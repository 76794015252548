"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameList = exports.startLinkStyle = exports.homePageStyle = exports.gameImage = exports.imageContainer = exports.imageGameBanner = void 0;
/** @jsxImportSource @emotion/react */
const react_1 = require("@emotion/react");
const imageGameBanner = (0, react_1.css) `
  margin: 2px;
  object-fit: contain;
  min-width: 100px;
  min-height: 60px;
`;
exports.imageGameBanner = imageGameBanner;
const imageContainer = (0, react_1.css) `
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  min-height: 50px;
  justify-content: center;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
exports.imageContainer = imageContainer;
const gameImage = (0, react_1.css) `
  height: 70px;
  width: 150px;
`;
exports.gameImage = gameImage;
const homePageStyle = (0, react_1.css) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  a {
    &:hover,
    &:link,
    &:visited {
      color: white;
    }
  }
  margin-bottom: 1em;
`;
exports.homePageStyle = homePageStyle;
const startLinkStyle = (0, react_1.css) `
  background-color: blue;
  border-radius: 10px;
  padding: 1em;
  margin: 1em;
`;
exports.startLinkStyle = startLinkStyle;
const gameList = (0, react_1.css) `
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
exports.gameList = gameList;
