"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonStyle = void 0;
const react_1 = require("@emotion/react");
const buttonStyle = (0, react_1.css) `
  border: none,
  background: none,
  padding: 0,
  cursor: pointer,
`;
exports.buttonStyle = buttonStyle;
