"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hostLink = exports.serverGamesNumbersStyle = exports.serverGamesTitleStyle = exports.mobileOnlyStyle = exports.noMobileStyle = exports.serverGamesListStyle = exports.slotRangesOptionsStyle = exports.noDecoration = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../global"); // adjust the import path as necessary
exports.noDecoration = (0, react_1.css) `
  text-decoration: none;
`;
exports.slotRangesOptionsStyle = (0, react_1.css) `
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
exports.serverGamesListStyle = (0, react_1.css) `
  ${global_1.largeScreen} {
    text-align: center;
    display: flex;
  }
  ${global_1.smallScreen} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    justify-items: space-between;
  }
`;
exports.noMobileStyle = (0, react_1.css) `
  ${global_1.smallScreen} {
    display: none;
  }
`;
exports.mobileOnlyStyle = (0, react_1.css) `
  ${global_1.largeScreen} {
    display: none;
  }
`;
exports.serverGamesTitleStyle = (0, react_1.css) `
  justify-self: right;
`;
exports.serverGamesNumbersStyle = (0, react_1.css) `
  justify-self: center;
`;
exports.hostLink = (0, react_1.css) `
  margin: 0.5em;
  cursor: pointer;
  width: 80%;
  display: block;
  background-color: rgb(36 120 73 / 26%);
  text-align: center;
  padding: 0.5em;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  transition: background-color 1s ease;

  &:hover {
    background-color: red;
  }

  ${global_1.notTinyScreen} {
    width: 100%;
  }
`;
