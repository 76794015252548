"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.couponContainer = exports.couponBar = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../../styles/global");
const couponBar = (0, react_1.css) `
  border-radius: 0 0 5px 5px;
  color: white;
  ${global_1.smallScreen}: {
    margin: 0 1em;
  }
`;
exports.couponBar = couponBar;
const couponContainer = (0, react_1.css) `
  display: flex;
  justify-content: space-around;
  color: white;
  align-items: center;
  width: 100%;
  height: 2em;
  background-color: #161183;
  ${global_1.smallScreen}: {
    height: auto;
    flexd-irection: column;
    text-align: center;
    width: 95%;
    margin: 0 auto;
  }
`;
exports.couponContainer = couponContainer;
