"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rowControl = exports.imageStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const imageStyle = (0, react_1.css) `
  object-fit: cover;
  width: 25px;
  height: 25px;
  padding: 1px;
  ${global_1.largeScreen} {
    width: 35px;
    height: 35px;
  }
`;
exports.imageStyle = imageStyle;
const rowControl = (0, react_1.css) `
  position: relative;
  flex-basis: 24%;
  max-height: 100%;
`;
exports.rowControl = rowControl;
