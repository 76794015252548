"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.amazonImages = exports.amazonLinkContainerFirst = exports.amazonLinkContainer = exports.whiteLink = exports.amazonLinks = exports.amazonTextContainer = void 0;
/** @jsxImportSource @emotion/react */
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const amazonTextContainer = (0, react_1.css) `
  display: flex;
  padding-right: 0.5em;
  flex-direction: column;
`;
exports.amazonTextContainer = amazonTextContainer;
const amazonLinks = (0, react_1.css) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  background-color: #f68700;
  box-sizing: border-box;
  padding: 0.5em;
  color: #0f1111;
`;
exports.amazonLinks = amazonLinks;
const whiteLink = (0, react_1.css) `
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2em;
  color: inherit;
`;
exports.whiteLink = whiteLink;
const amazonLinkContainer = (0, react_1.css) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 3px;
  background-color: #f3a847;
  ${global_1.notTinyScreen} {
    display: none;
  }
`;
exports.amazonLinkContainer = amazonLinkContainer;
const amazonLinkContainerFirst = (0, react_1.css) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 3px;
  background-color: #f3a847;
`;
exports.amazonLinkContainerFirst = amazonLinkContainerFirst;
const amazonImages = (0, react_1.css) `
  width: 100px;
  height: 100px;
`;
exports.amazonImages = amazonImages;
