"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageLogosStyle = exports.imageStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
exports.imageStyle = (0, react_1.css) `
  height: 40px;
  width: 40px;
  ${global_1.smallScreen} {
    width: 50%;
  }
`;
exports.imageLogosStyle = (0, react_1.css) `
  color: white;
  font-weight: bolder;
`;
