"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.versionNumberStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("./styles/global");
exports.versionNumberStyle = (0, react_1.css) `
  color: white;
  margin-right: 1em;
  text-align: right;
  background-color: #1f1f1f;
  width: 100%;
  justify-self: flex-end;
  font-weight: normal;
  font-size: 0.7vw;
  ${global_1.smallScreen} {
    font-size: 1em;
  }
`;
