"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageLogoContainer = exports.imageStyle = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global");
const imageStyle = (0, react_1.css) `
  transition: all 1s ease-out;
  padding: 0.5em;
  width: 300px;
  height: 55px;
  ${global_1.mediumScreen} {
    width: 200px;
    height: 37px;
  }
  ${global_1.smallScreen} {
    width: 150px;
    height: 28px;
  }
  ${global_1.tinyScreen} {
    width: 150px;
    height: 28px;
  }
  &:hover {
    background-color: red;
    border-radius: 5px;
  }
`;
exports.imageStyle = imageStyle;
const imageLogoContainer = (0, react_1.css) `
  padding-left: 0.5em;
`;
exports.imageLogoContainer = imageLogoContainer;
