"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.twitterContainerStyle = void 0;
const react_1 = require("@emotion/react");
exports.twitterContainerStyle = (0, react_1.css) `
  height: auto;
  margin: 0.5em;
  max-height: 500px;
  overflow: scroll;
`;
