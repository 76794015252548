"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = void 0;
const date_fns_1 = require("date-fns");
const formatDate = (date) => {
    try {
        return (0, date_fns_1.format)(new Date(date), 'dd/MM/yyyy'); // Adjust format as needed
    }
    catch (error) {
        console.error('Error formatting date', error);
        return 'Invalid Date';
    }
};
exports.formatDate = formatDate;
