"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
exports.default = monthNames;
