"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.establishedStyles = exports.trustPilotAreaContent = exports.dateEstablished = exports.controlPanel = exports.gridArea = exports.trustPilotArea = exports.mainImageLogo = exports.tpClassStyle = exports.noSmall = exports.buyFromLinks = void 0;
const react_1 = require("@emotion/react");
const global_1 = require("../styles/global"); // adjust the import path as necessary
const buyFromLinks = (0, react_1.css) `
  button {
    width: 100%;
  }
  ${global_1.smallScreen} {
    display: flex;
    min-height: 6em;
  }
`;
exports.buyFromLinks = buyFromLinks;
const noSmall = (0, react_1.css) `
  ${global_1.smallScreen} {
    display: none;
  }
`;
exports.noSmall = noSmall;
const tpClassStyle = (0, react_1.css) `
  width: 1.5em;
  height: 1.5em;
  font-size: 1em;
  background-color: red;
  border-radius: 50%;
  color: white;
  cursor: help;
`;
exports.tpClassStyle = tpClassStyle;
const mainImageLogo = (0, react_1.css) `
  grid-area: mainImageLogo;
  align-self: center;
  ${global_1.smallScreen} {
    width: auto;
    display: block;
    a {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 2rem;
    }
  }
`;
exports.mainImageLogo = mainImageLogo;
const trustPilotArea = (0, react_1.css) `
  grid-area: trustpilotArea;
  font-size: 0.75em;
  display: flex;
`;
exports.trustPilotArea = trustPilotArea;
const gridArea = (0, react_1.css) `
  grid-area: platforms;
`;
exports.gridArea = gridArea;
const controlPanel = (0, react_1.css) `
  grid-area: controlPanel;
`;
exports.controlPanel = controlPanel;
const dateEstablished = (0, react_1.css) `
  grid-area: established;
`;
exports.dateEstablished = dateEstablished;
const trustPilotAreaContent = (0, react_1.css) `
  display: flex;
  align-items: center;
  flex-direction: column;
  color: inherit;
`;
exports.trustPilotAreaContent = trustPilotAreaContent;
const establishedStyles = (0, react_1.css) `
  position: absolute;
  top: 40%;
  left: 31%;
  transform: rotate(5deg);
  font-weight: bolder;
  font-size: 0.35em;
`;
exports.establishedStyles = establishedStyles;
