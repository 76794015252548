"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const countryArray = [
    'usa',
    'en',
    'au',
    'br',
    'ca',
    'de',
    'fi',
    'fr',
    'gh',
    'in',
    'it',
    'ja',
    'nl',
    'nz',
    'pl',
    'ru',
    'sin',
    'sa',
    'es',
    'sw',
    'uk',
    'uae',
];
exports.default = countryArray;
