"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.couponText = exports.couponContainer = exports.noTextDecoration = void 0;
const react_1 = require("@emotion/react");
const noTextDecoration = (0, react_1.css) `
  & a,
  & a:hover,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: white;
  }
`;
exports.noTextDecoration = noTextDecoration;
const couponContainer = (0, react_1.css) `
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  min-width: 300px;
  font-size: 2rem;
  height: auto;
  margin: 0.5rem;
  background-color: grey;
  box-shadow: 5px 5px 5px #afafaf;
  &:hover {
    opacity: 0.5;
  }
`;
exports.couponContainer = couponContainer;
const couponText = (0, react_1.css) `
  font-size: 1.5em;
  background-color: grey;
  border-radius: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    padding: 0.3em;
    border-radius: 5px;
  }
  margin: 0 auto;
  text-align: center;
  p {
    margin: 0;
    font-size: 0.5em;
  }
`;
exports.couponText = couponText;
