"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redArrowStyle = exports.whiteArrowStyle = void 0;
const react_1 = require("@emotion/react");
const whiteArrowStyle = (0, react_1.css) `
  color: white;
`;
exports.whiteArrowStyle = whiteArrowStyle;
const redArrowStyle = (0, react_1.css) `
  color: red;
`;
exports.redArrowStyle = redArrowStyle;
