"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uniqueGames = [
    '7-days-to-die',
    'argo',
    'alien-arena',
    'alien-swarm',
    'american-truck-simulator',
    'ancestors-legacy',
    'animalia',
    'ark-survival-evolved',
    'ark-survival-evolved-genesis',
    'ark-survival-of-the-fittest',
    'arma-2',
    'arma-3',
    'arma-3-altis-life',
    'arma-3-breaking-point',
    'arma-3-exile-mod',
    'arma-reforger',
    'artemishea',
    'assetto-corsa',
    'astroneer',
    'atlas',
    'avorion',
    'battalion-1944',
    'baro-trauma',
    'battle-rush-2',
    'battle-rush',
    'battlefield-2',
    'beasts-of-bermuda',
    'beyond-the-wire',
    'black-ops-3',
    'blackwake',
    'broke-protocol',
    'call-of-duty-2',
    'call-of-duty-4',
    'call-of-duty-world-at-war',
    'call-of-duty',
    'chivalry',
    'citadel',
    'colony-survival',
    'conan-exiles',
    'contagion',
    'core-keeper',
    'counter-strike-1.6',
    'counter-strike-condition-zero',
    'counter-strike-source',
    'crafting-dead',
    'craftopia',
    'crea',
    'cryofall',
    'CSGO',
    'dark-and-light',
    'dark-messiah',
    'day-of-dragons',
    'day-of-defeat',
    'days-of-war',
    'dayz',
    'dead-matter',
    'dead-poly',
    'dino-d-day',
    'dont-starve-together',
    'dysterra',
    'dystopia',
    'eco-global-survival',
    'empyrion-galactic-survival',
    'enshrouded',
    'et-legacy',
    'factorio',
    'fear-the-night',
    'fistful-of-frags',
    'fiveM',
    'fragmented',
    'frozen-flame',
    'garrys-mod',
    'goldeneye-007',
    'ground-branch',
    'half-life-2',
    'hanako-honor-and-blade',
    'heat',
    'hellion',
    'hogwarts-legacy',
    'holdfast-nations-at-war',
    'humanitz',
    'hurtworld',
    'icarus',
    'insurgency',
    'insurgency-sandstorm',
    'insurgency',
    'interstellar-rift',
    'isles-of-yore',
    'just-cause-2',
    'just-cause-3',
    'karagon',
    'kerbal-space-program',
    'killing-floor-2',
    'killing-floor',
    'kingdom-of-atham',
    'leap',
    'left-4-dead-2',
    'life-is-feudal',
    'longvinter',
    'lost-oasis',
    'medal-of-honor-allied-assault',
    'medal-of-honor-spearhead',
    'medieval-engineers',
    'memories-of-mars',
    'military-conflict-vietnam',
    'minecraft',
    'minecraft-pocket-edition',
    'miscreated',
    'mordhau',
    'mount-and-blade-2-bannerlord',
    'mx-bikes',
    'necesse',
    'night-of-the-dead',
    'no-more-room-in-hell',
    'no-one-survived',
    'nuclear-dawn',
    'orelight',
    'out-of-reach',
    'outlaws-of-the-old-west',
    'outpost-zero',
    'palworld',
    'path-of-titans',
    'pixark',
    'post-scriptum',
    'primal-carnage',
    'primal-carnage-extinction',
    'primal-earth',
    'project-5-sightseer',
    'project-cars-2',
    'project-zomboid',
    'post-scriptum',
    'quake-3-arena',
    'quake-4',
    'quake-live',
    'quake-wars-enemy-territory',
    'rageMP',
    'reactive-drop',
    'red-orchestra-2',
    'reign-of-dwarf',
    'reign-of-kings',
    'renegade-x',
    'return-to-castle-wolfenstein',
    'rising-storm-2-vietnam',
    'rising-world',
    'risk-of-rain-2',
    'rust',
    'san-andreas-multiplayer',
    'satisfactory',
    'scum',
    'skyrim-together-reborn',
    'small-land',
    'sniper-elite-4',
    'sniper-elite-v2-remastered',
    'solace-crafting',
    'sons-of-the-forest',
    'space-engineers',
    'squad',
    'star-wars-jedi-knight-2-jedi-outcast',
    'star-wars-jedi-knight',
    'starbound',
    'starmade',
    'stationeers',
    'staxel',
    'subnautica',
    'subsistence',
    'survive-the-nights',
    'team-fortress-2',
    'terraria',
    'terraria-mobile',
    'the-black-death',
    'the-cenezoic-era',
    'the-forest',
    'the-front',
    'the-isle',
    'the-ship',
    'tinkertown',
    'unfortunate-spacemen',
    'unreal-tournament-2004',
    'unturned',
    'v-rising',
    'valheim',
    'wreckfest',
    'wurm-unlimited',
    'ylands',
];
exports.default = uniqueGames;
