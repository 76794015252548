"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const react_router_dom_1 = require("react-router-dom");
const react_i18next_1 = require("react-i18next");
const styles_1 = require("./styles");
const Bottom = () => {
    const { t } = (0, react_i18next_1.useTranslation)();
    const resetStorage = () => {
        if (__isBrowser__) {
            localStorage.removeItem('consent');
            localStorage.removeItem('answered');
            window.location.reload();
        }
    };
    return ((0, jsx_runtime_1.jsx)("footer", { children: (0, jsx_runtime_1.jsxs)("div", { className: "footer-style", css: styles_1.footerStyle, children: [(0, jsx_runtime_1.jsxs)("div", { className: "column-container", css: styles_1.footerColumnContainer, children: [(0, jsx_runtime_1.jsxs)("div", { css: {
                                gridArea: 'column1',
                                backgroundColor: '#1f1f1f',
                            }, children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.footerLink, to: {
                                        pathname: `/`,
                                    }, children: (0, jsx_runtime_1.jsx)("h3", { css: { fontWeight: 'bolder' }, children: (0, jsx_runtime_1.jsxs)("b", { children: [(0, jsx_runtime_1.jsx)("span", { css: { color: '#606060' }, children: "H" }), (0, jsx_runtime_1.jsx)("span", { css: { color: 'white' }, children: "OST" }), (0, jsx_runtime_1.jsx)("span", { css: { color: '#606060' }, children: "F" }), (0, jsx_runtime_1.jsx)("span", { css: { color: 'white' }, children: "ILTER" })] }) }) }), (0, jsx_runtime_1.jsx)("p", { children: "The most comprehensive compare site in the gaming industry. New games are added as soon as they are released." })] }), (0, jsx_runtime_1.jsxs)("div", { css: [
                                styles_1.footerLink,
                                {
                                    color: 'inherit',
                                    gridArea: 'column2',
                                    backgroundColor: '#1f1f1f',
                                },
                                styles_1.footerLink,
                            ], children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.footerLink, to: {
                                        pathname: `/host-area/`,
                                    }, children: (0, jsx_runtime_1.jsx)("h3", { css: { color: 'white' }, children: (0, jsx_runtime_1.jsx)("b", { children: "Adding Your Host" }) }) }), (0, jsx_runtime_1.jsxs)("p", { css: { color: 'white' }, children: ["If you would like to be added to our website please email us at", ' ', (0, jsx_runtime_1.jsx)("a", { css: { color: 'inherit' }, href: "mailto:admin@comparegameserverhosting.com", children: "HostFilter" })] })] }), (0, jsx_runtime_1.jsxs)("div", { className: "admin-links", css: styles_1.adminLinks2, children: [(0, jsx_runtime_1.jsx)("h3", { children: "Site Admin" }), (0, jsx_runtime_1.jsxs)("div", { className: "admin-button-links", css: styles_1.adminLinks, children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.adminButtonLink, to: {
                                                pathname: `/contact-us/`,
                                            }, children: (0, jsx_runtime_1.jsx)("p", { children: t('titles.contact-us') }) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.adminButtonLink, to: {
                                                pathname: `/roadmap/`,
                                            }, children: (0, jsx_runtime_1.jsx)("p", { children: t('titles.updates') }) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { css: styles_1.adminButtonLink, to: {
                                                pathname: `/privacy/`,
                                            }, children: (0, jsx_runtime_1.jsx)("p", { children: t('titles.privacy-policy') }) }), (0, jsx_runtime_1.jsx)("button", { css: styles_1.adminButtonLink, onClick: resetStorage, children: "Reset cookies" })] })] })] }), (0, jsx_runtime_1.jsx)("div", { className: "logo", css: styles_1.logo, children: (0, jsx_runtime_1.jsxs)("p", { children: [(0, jsx_runtime_1.jsx)("span", { css: { color: '#606060' }, children: "H" }), (0, jsx_runtime_1.jsx)("span", { css: { color: 'white' }, children: "OST" }), (0, jsx_runtime_1.jsx)("span", { css: { color: '#606060' }, children: "F" }), (0, jsx_runtime_1.jsx)("span", { css: { color: 'white' }, children: "ILTER Copyright 2014" })] }) })] }) }));
};
exports.default = Bottom;
